import { Home, Settings } from "react-feather";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartFlatbedSuitcase,
  faPersonWalkingLuggage,
  faBoxesPacking,
  faRankingStar,
  faStore,
  faCartArrowDown,
  faFileLines,
  faTags,
  faClipboardQuestion,
  faIcons,
  faList,
  faSuitcase,
  faExclamationTriangle,
  faHandHoldingDollar,
} from "@fortawesome/free-solid-svg-icons";

export default [
  {
    id: "leftBehind",
    title: "Left-Behind",
    icon: <FontAwesomeIcon icon={faCartFlatbedSuitcase} />,
    children: [
      {
        id: "list",
        title: "List",
        icon: <FontAwesomeIcon icon={faList} style={{ fontSize: "1.3rem" }} />,
        navLink: "/baggage-management/left-behind/list",
      },
      {
        id: "reasons",
        title: "Reasons",
        icon: (
          <FontAwesomeIcon
            icon={faPersonWalkingLuggage}
            style={{ fontSize: "1.3rem" }}
          />
        ),
        navLink: "/baggage-management/left-behind/reasons",
      },
    ],
  },
  {
    id: "stock",
    title: "Stock",
    icon: <FontAwesomeIcon icon={faStore} />,
    children: [
      {
        id: "items",
        title: "Items",
        icon: (
          <FontAwesomeIcon
            icon={faBoxesPacking}
            style={{ fontSize: "1.3rem" }}
          />
        ),
        navLink: "/baggage-management/stock/items",
      },
      // {
      //   id: "quantityTypes",
      //   title: "Quantity Types",
      //   icon: (
      //     <FontAwesomeIcon
      //       icon={faRankingStar}
      //       style={{ fontSize: "1.3rem" }}
      //     />
      //   ),
      //   navLink: "/baggage-management/stock/quantity-type",
      // },
      {
        id: "orders",
        title: "Orders",
        icon: (
          <FontAwesomeIcon
            icon={faCartArrowDown}
            style={{ fontSize: "1.3rem" }}
          />
        ),
        navLink: "/baggage-management/stock/orders",
      },
      {
        id: "histories",
        title: "Histories",
        icon: (
          <FontAwesomeIcon icon={faFileLines} style={{ fontSize: "1.3rem" }} />
        ),
        navLink: "/baggage-management/stock/histories",
      },
      {
        id: "orderTags",
        title: "Order Tags",
        icon: <FontAwesomeIcon icon={faTags} style={{ fontSize: "1.3rem" }} />,
        navLink: "/baggage-management/stock/order-tags",
      },
    ],
  },
  {
    id: "foundItems",
    title: "Found-Items",
    icon: <FontAwesomeIcon icon={faClipboardQuestion} />,
    children: [
      {
        id: "list",
        title: "List",
        icon: <FontAwesomeIcon icon={faList} style={{ fontSize: "1.3rem" }} />,
        navLink: "/baggage-management/found-items/list",
      },
      {
        id: "itemTypes",
        title: "Item Types",
        icon: <FontAwesomeIcon icon={faIcons} style={{ fontSize: "1.3rem" }} />,
        navLink: "/baggage-management/found-items/item-types",
      },
    ],
  },
  {
    id: "lzBaggages",
    title: "LZ-Bag",
    icon: <FontAwesomeIcon icon={faSuitcase} />,
    children: [
      {
        id: "list",
        title: "List",
        icon: <FontAwesomeIcon icon={faList} style={{ fontSize: "1.3rem" }} />,
        navLink: "/baggage-management/lz-baggage/list",
      },
      {
        id: "histories",
        title: "Histories",
        icon: (
          <FontAwesomeIcon icon={faFileLines} style={{ fontSize: "1.3rem" }} />
        ),
        navLink: "/baggage-management/lz-baggage/histories",
      },
    ],
  },
  {
    id: "WrongLoadWrongTag",
    title: "Wrong Load/Tag",
    icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
    navLink: "/baggage-management/wrongload-wrongtag/list",
  },
  {
    id: "Compensation",
    title: "Compensation",
    icon: <FontAwesomeIcon icon={faHandHoldingDollar} />,
    navLink: "/baggage-management/compensation/list",
  },
  {
    id: "settings",
    title: "Settings",
    icon: <Settings size={20} />,
    navLink: "/baggage-management/settings",
  },
];
