import { mainApi } from "../mainApi";

export const compensationListApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getcompensationList: builder.query({
      query: (filters) => ({
        url: `/compensations/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "compensationList", id: "Search" }],
    }),
    addcompensationItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/compensations`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "compensationList", id: "Search" }],
    }),
    getcompensation: builder.query({
      query: (id) => ({
        url: `/compensations/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "compensationList", id: "Details" }],
    }),
    updateCompensationItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/compensations/${formData.id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "compensationList", id: "Search" }],
    }),
    acceptCompensationItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/compensations/accept`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "compensationList", id: "Search" }],
    }),
    rejectCompensationItem: builder.mutation({
      query: ({ id, rejectionReason }) => {
        return {
          url: `/compensations/reject`,
          method: "POST",
          body: { id, rejectionReason },
        };
      },
      invalidatesTags: [{ type: "compensationList", id: "Search" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetcompensationListQuery,
  useAddcompensationItemMutation,
  useGetcompensationQuery,
  useUpdateCompensationItemMutation,
  useAcceptCompensationItemMutation,
  useRejectCompensationItemMutation,
} = compensationListApi;
