import { Fragment } from "react";
import { Dropdown } from "primereact/dropdown";
import {
  faBoxesPacking,
  faUsers,
  faUserTie,
  faEnvelope,
  faNoteSticky,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Badge } from "reactstrap";
import { Edit, Trash2 } from "react-feather";
import PermissionButton from "@src/components/shared/PermissionButton";

const messageTypes = ["ldm", "prl", "apis", "pnl", "adl"];

const getMessageType = (type) => {
  switch (type) {
    case "ldm":
      return faBoxesPacking;
    case "prl":
      return faUsers;
    case "pnl":
      return faUsers;
    case "adl":
      return faUsers;
    case "apis":
      return faUserTie;
  }
};

const addressTypes = ["sita", "mail", "sitaMail"];

const getAddressType = (addressType) => {
  switch (addressType) {
    case "sita":
      return faNoteSticky;
    case "mail":
      return faEnvelope;
    case "sitaMail":
      return faSquareEnvelope;
  }
};

const messageTypeBodyTemplate = (rowData) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(rowData.messageTypeCode)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.messageTypeCode}
    </span>
  );
};

const messageTypeFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={messageTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={messageTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const messageTypeItemTemplate = (option) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );
};

const addressTypeBodyTemplate = (rowData) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getAddressType(rowData.addressType)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.addressType}
    </span>
  );
};

const addressTypeFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={addressTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={addressTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const addressTypeItemTemplate = (option) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getAddressType(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );
};

const actionsBodyTemplate = (rowData, handleEditClick, handleDeleteClick) => {
  return (
    <div className="justify-content-center flex-column text-center">
      <PermissionButton
        className="btn-icon"
        color="flat-dark"
        onClick={() => {
          handleEditClick({ ...rowData });
        }}
        permission="sim.message-address.update"
      >
        <Edit size={16} />
      </PermissionButton>
      <PermissionButton
        className="btn-icon"
        color="flat-danger"
        onClick={() => {
          handleDeleteClick({ ...rowData });
        }}
        permission="sim.message-address.delete"
      >
        <Trash2 size={16} />
      </PermissionButton>
    </div>
  );
};

const AddressBodyTemplate = ({ address }) =>
  address.split(";").map((mail, index) => (
    <Fragment key={index}>
      <Badge color="light-secondary" className="rounded-pill p-15">
        {mail.trim()}
      </Badge>
      {index < address.split(";").length - 1 && "     "}
    </Fragment>
  ));

const messageAddressesColumns = (handleEditClick, handleDeleteClick) => {
  return [
    {
      header: "Country",
      filter: true,
      field: "country",
      sortable: false,
    },
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
    },
    {
      header: "Address",
      filter: true,
      field: "address",
      sortable: false,
      body: AddressBodyTemplate,
      style: { maxWidth: "10%" },
      bodyStyle: { maxWidth: "10%" },
    },
    {
      header: "Flight No",
      filter: true,
      field: "flightNumber",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Message Type",
      filter: true,
      filed: "messageTypeCode",
      filterField: "messageType.code",
      sortable: false,
      body: messageTypeBodyTemplate,
      filterElement: messageTypeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Address Type",
      filter: true,
      field: "addressType",
      sortable: false,
      body: addressTypeBodyTemplate,
      filterElement: addressTypeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, handleDeleteClick),
      bodyStyle: { width: "10%" },
    },
  ];
};

export default messageAddressesColumns;
