import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import toast from 'react-hot-toast';
import { handleApiError } from '@src/utility/errorHandler';

export const mealCycleApi = createApi({
    reducerPath: 'mealCycleApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['MealCycle'],
    endpoints: (builder) => ({
        getMealCycles: builder.query({
            query: () => ({
                url: '/api/meal-cycle/list',
                method: 'GET'
            }),
            transformResponse: (response) => {
                return { items: response };
            },
            providesTags: ['MealCycle'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch meal cycles');
                }
            }
        }),

        getMeals: builder.query({
            query: () => ({
                url: '/api/meal/list',
                method: 'GET'
            }),
            transformResponse: (response) => {
                return { items: response };
            },
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch meals');
                }
            }
        }),

        getMealCycleMealsById: builder.query({
            query: (id) => ({
                url: `/api/meal-cycle/${id}/meals`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
            providesTags: ['MealCycle'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch meal cycle');
                }
            }
        }),

        addMealToMealCycle: builder.mutation({
            query: (data) => ({
                url: '/api/meal-cycle/meal-cycle-meal',
                method: 'POST',
                data,
            }),
            invalidatesTags: ['MealCycle'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal added to cycle successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to add meal to cycle');
                }
            }
        }),

        addMealCycle: builder.mutation({
            query: (mealCycleData) => ({
                url: '/api/meal-cycle',
                method: 'POST',
                data: mealCycleData,
            }),
            invalidatesTags: ['MealCycle'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal cycle added successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to add meal cycle');
                }
            }
        }),

        updateMealCycle: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/meal-cycle/${id}`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['MealCycle'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal cycle updated successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to update meal cycle');
                }
            }
        }),
        updateMealCycleMealStatus: builder.mutation({
            query: ({ mealCycleId, mealId, status }) => {
                console.log("mealCycleId", mealCycleId, "mealId", mealId, "status", status);
                return ({
                    url: `/api/meal-cycle/${mealCycleId}/meal/${mealId}/status`,
                    method: 'PUT',
                    data: { status },
                })
            },
            invalidatesTags: ['MealCycle'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal status updated successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to update meal status');
                }
            }
        })
    }),
});

// Export hooks for usage in components
export const {
    useGetMealCyclesQuery,
    useGetMealsQuery,
    useAddMealCycleMutation,
    useGetMealCycleMealsByIdQuery,
    useUpdateMealCycleMutation,
    useAddMealToMealCycleMutation,
    useUpdateMealCycleMealStatusMutation
} = mealCycleApi;

