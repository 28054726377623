import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import {
  useUpdateCompensationItemMutation,
  useGetcompensationQuery,
} from "@src/redux/compensation/list";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextInput,
  SelectInput,
  CalendarInput,
  UploadFile,
  PhoneInput,
} from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { useGetStationListQuery } from "@src/redux/station/station";
import { useGetGroundHandlerListQuery } from "@src/redux/ground-handler/list";
import { useGetCurrencyListQuery } from "@src/redux/currency/list";
import { format } from "date-fns";
import {
  faPlane,
  faCalendar,
  faBuilding,
  faMoneyBill,
  faUser,
  faPhone,
  faTag,
  faBoxes,
  faBank,
  faMoneyBillTransfer,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";
import FileDetailView from "@src/components/shared/FileDetailView";
import {
  caseTypeOptions,
  flightTypeOptions,
  bankAccountTypeOptions,
  getFilteredSubTypeOptions,
} from "./constants";

const compensationSchema = yup.object().shape({
  // Case Info
  claimNumber: yup.string().required("Claim number is required"),
  station: yup
    .string()
    .required("Station is required")
    .max(3, "Station must be 3 characters or less"),
  caseType: yup.string().required("Case type is required"),
  subType: yup
    .string()
    .required("Sub type is required")
    .test("validSubType", "Invalid sub type for case type", (value, ctx) => {
      const caseType = ctx.parent.caseType;
      if (caseType === "DPR") {
        return ["DMG", "PIL"].includes(value);
      } else if (caseType === "AHL") {
        return ["DELAY", "FINAL"].includes(value);
      }
      return false;
    }),

  // Flight Info
  flightNumber: yup.string().required("Flight number is required"),
  flightDate: yup
    .date()
    .typeError("Please select a valid flight date")
    .required("Flight date is required")
    .max(new Date(), "Flight date cannot be in the future"),
  flightTypeEnum: yup.string().required("Flight type is required"),
  faultStation: yup
    .string()
    .required("Fault station is required")
    .max(3, "Fault station must be 3 characters or less"),
  amount: yup.number().required().typeError("Amount must be a number"),

  // Passenger Info
  passenger: yup.object().shape({
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(
        /^\+\d{1,3}\d{9,}$/,
        "Please enter a valid phone number with country code"
      ),
    pnr: yup
      .string()
      .required("PNR is required")
      .matches(
        /^[A-Z0-9]{6}$/,
        "PNR must be 6 characters long and contain only letters and numbers"
      )
      .transform((value) => value?.toUpperCase()),
    checkedBagsCount: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .min(0, "Checked bags count must be greater than or equal to 0"),
    checkedBagsWeight: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .min(0, "Checked bags weight must be greater than or equal to 0"),
    hasInsurance: yup.boolean().nullable().default(false),
    excessBagPaid: yup.number().nullable().default(0),
    receivedBagsCount: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .min(0, "Received bags must be greater than or equal to 0"),
    receivedBagsWeight: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .min(0, "Received bags weight must be greater than or equal to 0"),
    missingBagsCount: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .min(0, "Missing bags must be greater than or equal to 0"),
    missingBagsWeight: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .min(0, "Missing weight must be greater than or equal to 0"),
    passengerName: yup.string().required("Passenger name is required"),
    tagNo: yup.string().required("Tag number is required"),
  }),

  // Additional Info
  tracingResult: yup.string().nullable(),

  // Bank Details - Optional
  bankDetails: yup.object().shape({
    bankAccountType: yup
      .string()
      .typeError("Bank account type is required")
      .required("Bank account type is required"),
    bankName: yup
      .string()
      .typeError("Bank name is required")
      .required("Bank name is required"),
    iban: yup.string().when("bankAccountType", {
      is: "Local_Account",
      then: yup
        .string()
        .required("IBAN is required")
        .transform((value) => value?.toUpperCase())
        .test("validSaudiIBAN", "Invalid Saudi IBAN", (value) => {
          if (!value) return false;
          const iban = value.replace(/\s/g, "").toUpperCase();
          if (iban.length !== 24 || !iban.startsWith("SA")) return false;
          const rearrangedIBAN = iban.substring(4) + iban.substring(0, 4);
          const numericIBAN = rearrangedIBAN
            .split("")
            .map((c) => (isNaN(c) ? (c.charCodeAt(0) - 55).toString() : c))
            .join("");
          return BigInt(numericIBAN) % 97n === 1n;
        }),
    }),
    accountNumber: yup.string().when("bankAccountType", {
      is: "International_Account",
      then: yup
        .string()
        .required("Account number is required")
        .length(12, "Account number must be 12 characters"),
    }),
    swiftCode: yup.string().required("Swift code is required"),
    firstName: yup.string().required("First name is required"),
    middleName: yup.string().required("Middle name is required"),
    lastName: yup.string().required("Last name is required"),
    currency: yup
      .string()
      .typeError("Currency is required")
      .required("Currency is required"),
    isOtherPerson: yup.boolean().default(false),
  }),

  // Deduction Details - Optional
  compensationDeductionDetail: yup
    .object()
    .shape({
      isDeduction: yup.boolean().required("Is deduction is required"),
      deductionDate: yup
        .date()
        .nullable()
        .when("isDeduction", {
          is: true,
          then: yup
            .date()
            .required("Deduction date is required")
            .max(new Date(), "Deduction date cannot be in the future"),
        }),
      deductionReason: yup
        .string()
        .nullable()
        .when("isDeduction", {
          is: true,
          then: yup
            .string()
            .required("Deduction reason is required")
            .max(500, "Deduction reason must be 500 characters or less"),
        }),
      handlerName: yup
        .string()
        .nullable()
        .max(100, "Handler name must be 100 characters or less"),
    })
    .nullable(),
});

const UpdateCompensation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateCompensationItem, { isLoading }] =
    useUpdateCompensationItemMutation();
  const { data: compensationData, isLoading: isLoadingData } =
    useGetcompensationQuery(id);
  const { data: stationList } = useGetStationListQuery();
  const { data: currencyList } = useGetCurrencyListQuery();
  const { data: groundHandlerList } = useGetGroundHandlerListQuery();
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [filteredGroundHandlers, setFilteredGroundHandlers] = useState([]);

  const updateGroundHandlers = (selectedStation) => {
    if (!selectedStation || !groundHandlerList) {
      setFilteredGroundHandlers([]);
      return;
    }

    const filtered = groundHandlerList.filter(
      (gh) => gh.airportId === selectedStation
    );
    setFilteredGroundHandlers(filtered);

    const currentHandlerName = formMethods.watch(
      "compensationDeductionDetail.handlerName"
    );
    const isCurrentHandlerValid = filtered.some(
      (gh) => gh.groundHandlerId === currentHandlerName
    );
    if (!isCurrentHandlerValid) {
      formMethods.setValue("compensationDeductionDetail.handlerName", "");
    }
  };

  const formMethods = useForm({
    resolver: yupResolver(compensationSchema),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (compensationData) {
      // Set existing files
      setExistingFiles(compensationData.files || []);

      // Set form values
      formMethods.reset({
        claimNumber: compensationData.claimNumber,
        station: compensationData.station,
        caseType: compensationData.caseType?.toUpperCase(),
        subType: compensationData.subType?.toUpperCase(),
        flightNumber: compensationData.flightNumber,
        flightDate: compensationData.flightDate
          ? new Date(compensationData.flightDate)
          : null,
        flightTypeEnum: compensationData.flightTypeEnum?.toLowerCase(),
        faultStation: compensationData.faultStation,
        amount: compensationData.amount,
        passenger: {
          phoneNumber: compensationData.passenger?.phoneNumber || "",
          pnr: compensationData.passenger?.pnr || "",
          checkedBagsCount: compensationData.passenger?.checkedBagsCount || 0,
          checkedBagsWeight: compensationData.passenger?.checkedBagsWeight || 0,
          hasInsurance: compensationData.passenger?.hasInsurance || false,
          excessBagPaid: compensationData.passenger?.excessBagPaid || 0,
          receivedBagsCount: compensationData.passenger?.receivedBagsCount || 0,
          receivedBagsWeight:
            compensationData.passenger?.receivedBagsWeight || 0,
          missingBagsCount: compensationData.passenger?.missingBagsCount || 0,
          missingBagsWeight: compensationData.passenger?.missingBagsWeight || 0,
          passengerName: compensationData.passenger?.passengerName || "",
          tagNo: compensationData.passenger?.tagNo || "",
        },
        tracingResult: compensationData.tracingResult || "",
        bankDetails: {
          bankAccountType:
            compensationData.bankDetails?.bankAccountType?.toLowerCase() || "",
          bankName: compensationData.bankDetails?.bankName || "",
          iban: compensationData.bankDetails?.iban || "",
          accountNumber: compensationData.bankDetails?.accountNumber || "",
          swiftCode: compensationData.bankDetails?.swiftCode || "",
          firstName: compensationData.bankDetails?.firstName || "",
          middleName: compensationData.bankDetails?.middleName || "",
          lastName: compensationData.bankDetails?.lastName || "",
          currency: compensationData.bankDetails?.currency || "",
          isOtherPerson: compensationData.bankDetails?.isOtherPerson || false,
        },
        compensationDeductionDetail: {
          isDeduction:
            compensationData.compensationDeductionDetail?.isDeduction || false,
          deductionDate: compensationData.compensationDeductionDetail
            ?.deductionDate
            ? new Date(
                compensationData.compensationDeductionDetail.deductionDate
              )
            : null,
          deductionReason:
            compensationData.compensationDeductionDetail?.deductionReason || "",
          handlerName:
            compensationData.compensationDeductionDetail?.handlerName || "",
        },
      });

      // Update ground handlers based on station
      updateGroundHandlers(compensationData.station);
    }
  }, [compensationData]);

  useEffect(() => {
    const subscription = formMethods.watch((value, { name }) => {
      if (name === "station") {
        updateGroundHandlers(value.station);
      }
    });
    return () => subscription.unsubscribe();
  }, [formMethods, groundHandlerList]);

  const handleDeleteFile = async (file) => {
    try {
      setExistingFiles(existingFiles.filter((f) => f.id !== file.id));
      SuccessToast("File marked for deletion!");
    } catch (error) {
      console.error("Error marking file for deletion:", error);
    }
  };

  const handleFormSubmit = async (formData) => {
    try {
      const compensationFormData = new FormData();

      // Add ID
      compensationFormData.append("id", id);

      // Add new files
      files.forEach((file) => {
        compensationFormData.append("newFiles", file);
      });

      // Add old files IDs
      const remainingOldFiles = existingFiles.map((file) => file.id);
      compensationFormData.append("oldFilesIds", remainingOldFiles.join(","));

      // Format dates and transform PNR
      const formattedData = {
        ...formData,
        passenger: {
          ...formData.passenger,
          pnr: formData.passenger.pnr.toUpperCase(),
        },
        flightDate: format(new Date(formData.flightDate), "yyyy-MM-dd"),
        compensationDeductionDetail: formData.compensationDeductionDetail
          .isDeduction
          ? {
              ...formData.compensationDeductionDetail,
              deductionDate: format(
                new Date(formData.compensationDeductionDetail.deductionDate),
                "yyyy-MM-dd"
              ),
            }
          : formData.compensationDeductionDetail,
      };

      // Append all other form data
      Object.entries(formattedData).forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          value !== null &&
          !(value instanceof File)
        ) {
          compensationFormData.append(key, JSON.stringify(value));
        } else {
          compensationFormData.append(key, value);
        }
      });
      compensationFormData.id = id;
      await updateCompensationItem(compensationFormData).unwrap();
      SuccessToast("Compensation Updated Successfully!");
      navigate("/baggage-management/compensation/list");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  if (isLoadingData) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <BreadCrumbs
        pageName="updateCompensation"
        pageTitle="Update Compensation"
      />
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <Row>
            <Col lg="12" md="12">
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle tag="h4">Case Information</CardTitle>
                  <div>
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      color="primary"
                      className="me-1"
                    >
                      Update
                    </LoadingButton>
                    <Button
                      type="button"
                      color="warning"
                      onClick={() =>
                        navigate("/baggage-management/compensation/list")
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="info-container">
                    <br />
                    <Row>
                      <Col md={3}>
                        <TextInput
                          label="Claim Number"
                          name="claimNumber"
                          icon={faTag}
                          twoLines
                        />
                      </Col>
                      <Col md={3}>
                        <SelectInput
                          twoLines
                          label="Station"
                          name="station"
                          icon={faBuilding}
                          options={
                            stationList?.map((station) => ({
                              value: station.id,
                              label: station.id,
                            })) || []
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <SelectInput
                          twoLines
                          label="Case Type"
                          name="caseType"
                          options={caseTypeOptions}
                          changeCallback={(e) => {
                            formMethods.setValue("subType", "");
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <SelectInput
                          twoLines
                          label="Sub Type"
                          name="subType"
                          options={getFilteredSubTypeOptions(
                            formMethods.watch("caseType")
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Flight Information</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Flight Number"
                          name="flightNumber"
                          icon={faPlane}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <CalendarInput
                          twoLines
                          label="Flight Date"
                          name="flightDate"
                          icon={faCalendar}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <SelectInput
                          twoLines
                          label="Flight Type"
                          name="flightTypeEnum"
                          options={flightTypeOptions}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <SelectInput
                          twoLines
                          label="Fault Station"
                          name="faultStation"
                          icon={faBuilding}
                          options={
                            stationList?.map((station) => ({
                              value: station.id,
                              label: station.id,
                            })) || []
                          }
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Amount"
                          name="amount"
                          type="number"
                          icon={faMoneyBill}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Passenger Information</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Passenger Name"
                          name="passenger.passengerName"
                          icon={faUser}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <PhoneInput
                          twoLines
                          label="Phone Number"
                          name="passenger.phoneNumber"
                          icon={faPhone}
                          placeholder="Enter phone number"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          icon={faTag}
                          twoLines
                          label="PNR"
                          name="passenger.pnr"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Tag Number"
                          name="passenger.tagNo"
                          icon={faTag}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Checked Bags Count"
                          name="passenger.checkedBagsCount"
                          type="number"
                          icon={faBoxes}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Checked Bags Weight"
                          name="passenger.checkedBagsWeight"
                          type="number"
                          icon={faWeight}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Received Bags Count"
                          name="passenger.receivedBagsCount"
                          type="number"
                          icon={faBoxes}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Received Bags Weight"
                          name="passenger.receivedBagsWeight"
                          type="number"
                          icon={faWeight}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Missing Bags Count"
                          name="passenger.missingBagsCount"
                          type="number"
                          icon={faBoxes}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Missing Bags Weight"
                          name="passenger.missingBagsWeight"
                          type="number"
                          icon={faWeight}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Excess Bag Paid"
                          name="passenger.excessBagPaid"
                          type="number"
                          icon={faBoxes}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="form-check mt-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="hasInsurance"
                          {...formMethods.register("passenger.hasInsurance")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="hasInsurance"
                        >
                          Has Insurance
                        </label>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Bank Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <div className="mb-2">
                        <SelectInput
                          twoLines
                          label="Bank Account Type"
                          name="bankDetails.bankAccountType"
                          icon={faBank}
                          options={bankAccountTypeOptions}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Bank Name"
                          name="bankDetails.bankName"
                          icon={faBank}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="IBAN"
                          name="bankDetails.iban"
                          icon={faBank}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Account Number"
                          name="bankDetails.accountNumber"
                          icon={faBank}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Swift Code"
                          name="bankDetails.swiftCode"
                          icon={faBank}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="First Name"
                          name="bankDetails.firstName"
                          icon={faUser}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Middle Name"
                          name="bankDetails.middleName"
                          icon={faUser}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <TextInput
                          twoLines
                          label="Last Name"
                          name="bankDetails.lastName"
                          icon={faUser}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <SelectInput
                          twoLines
                          label="Currency"
                          name="bankDetails.currency"
                          icon={faMoneyBill}
                          options={
                            currencyList?.map((currency) => ({
                              value: currency.currencyCode,
                              label: `${currency.description} (${currency.currencyCode})`,
                            })) || []
                          }
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="form-check mt-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isOtherPerson"
                          {...formMethods.register("bankDetails.isOtherPerson")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isOtherPerson"
                        >
                          Is Other Person
                        </label>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Deduction Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <div className="form-check mb-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isDeduction"
                          {...formMethods.register(
                            "compensationDeductionDetail.isDeduction",
                            {
                              setValueAs: (value) => !!value,
                            }
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isDeduction"
                        >
                          Is Deduction
                        </label>
                      </div>
                    </Col>
                    {formMethods.watch(
                      "compensationDeductionDetail.isDeduction"
                    ) && (
                      <>
                        <Col md={4}>
                          <div className="mb-2">
                            <CalendarInput
                              twoLines
                              label="Deduction Date"
                              name="compensationDeductionDetail.deductionDate"
                              icon={faCalendar}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-2">
                            <TextInput
                              twoLines
                              label="Deduction Reason"
                              name="compensationDeductionDetail.deductionReason"
                              icon={faMoneyBillTransfer}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <SelectInput
                            twoLines
                            label="Handler Name"
                            name="compensationDeductionDetail.handlerName"
                            icon={faBuilding}
                            options={
                              filteredGroundHandlers?.map((gh) => ({
                                value: gh.groundHandlerId,
                                label: gh.groundHandlerId,
                              })) || []
                            }
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Additional Information</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <TextInput
                        label="Tracing Result"
                        name="tracingResult"
                        type="textarea"
                        rows="3"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Files</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <UploadFile
                        files={files}
                        setFiles={setFiles}
                        formMethods={formMethods}
                      />
                      <CardTitle tag="h5" className="mb-0">
                        <span className="section-label">Existed files</span>
                      </CardTitle>
                      <FileDetailView
                        files={existingFiles}
                        category="Compensation"
                        onDelete={handleDeleteFile}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Fragment>
  );
};

export default UpdateCompensation;
