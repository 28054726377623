import React, { useState } from "react";
import * as yup from "yup";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Label,
} from "reactstrap";
import {
  faPlane,
  faCalendar,
  faBoxes,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import {
  TextInput,
  SelectInput,
  CalendarInput,
  UploadFile,
} from "@src/components/inputs";
import { useAddWrongLoadWrongTagItemMutation } from "@src/redux/wrongload-wrongtag/list";
import { useGetStationListQuery } from "@src/redux/station/station";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const AddWrongLoadWrongTag = () => {
  const [createWrongLoadWrongTag, { isLoading }] =
    useAddWrongLoadWrongTagItemMutation();
  const { data: stationList } = useGetStationListQuery();
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const [showTransit, setShowTransit] = useState(false);

  const wrongLoadSchema = yup.object().shape({
    flightNumber: yup.string().required("Flight number is required."),
    flightDate: yup.date().required("Flight date is required."),
    destination: yup.string().required("Destination is required."),
    faultStation: yup.string().required("Fault station is required."),
    paxToFlightNumber: yup.string().nullable(),
    paxCheckedTo: yup.string().nullable(),
    paxToFlightDate: yup.date().nullable(),
    paxBookingReference: yup.string().nullable(),
    status: yup
      .string()
      .required("Status is required.")
      .oneOf(["wrl", "wrt"], "Invalid status."),
    totalPieces: yup
      .number()
      .nullable()
      .positive("Total pieces must be greater than 0.")
      .integer("Total pieces must be an integer."),
    ohd: yup.string().nullable(),
    claimNumber: yup.string().nullable(),
    tagNumber: yup.string().nullable(),
    wrtTagNumber: yup.string().nullable(),
    wrtTagBookingReference: yup.string().nullable(),
    comments: yup.string().nullable(),
    files: yup
      .array()
      .min(1, "At least one file is required.")
      .required("Files are required."),
    hasTransit: yup.boolean(),
    transitStation: yup.string().when("hasTransit", {
      is: true,
      then: () => yup.string().required("Transit station is required"),
      otherwise: () => yup.string().nullable(),
    }),
    transitFlightNumber: yup.string().when("hasTransit", {
      is: true,
      then: () => yup.string().required("Transit flight number is required"),
      otherwise: () => yup.string().nullable(),
    }),
    transitFlightDate: yup.date().when("hasTransit", {
      is: true,
      then: () => yup.date().required("Transit flight date is required"),
      otherwise: () => yup.date().nullable(),
    }),
  });

  const formMethods = useForm({
    resolver: yupResolver(wrongLoadSchema),
    mode: "onSubmit",
    defaultValues: {
      flightNumber: "",
      flightDate: "",
      destination: "",
      faultStation: "",
      paxToFlightNumber: "",
      paxCheckedTo: "",
      paxToFlightDate: null,
      paxBookingReference: "",
      status: "Wrl",
      totalPieces: null,
      ohd: "",
      claimNumber: "",
      tagNumber: "",
      wrtTagNumber: "",
      wrtTagBookingReference: "",
      comments: "",
      files: [],
      hasTransit: false,
      transitStation: "",
      transitFlightNumber: "",
      transitFlightDate: null,
    },
  });

  // Watch the status field
  const status = useWatch({
    control: formMethods.control,
    name: "status",
  });

  const hasTransit = useWatch({
    control: formMethods.control,
    name: "hasTransit",
  });

  const handleFormSubmit = async (formData) => {
    const wrongLoadData = new FormData();

    if (formData.status === "wrl") {
      formData.wrtTagNumber = "";
      formData.wrtTagBookingReference = "";
    }

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "files") {
        files.forEach((file) => wrongLoadData.append(key, file));
      } else {
        const formattedValue =
          key === "flightDate" ||
          key === "paxToFlightDate" ||
          key === "transitFlightDate"
            ? value
              ? format(new Date(value), "yyyy-MM-dd")
              : null
            : value;

        if (
          (formattedValue !== null && formattedValue !== undefined) ||
          (formData.status === "wrl" &&
            (key === "wrtTagNumber" || key === "wrtTagBookingReference"))
        ) {
          wrongLoadData.append(key, formattedValue);
        }
      }
    });

    await createWrongLoadWrongTag(wrongLoadData)
      .unwrap()
      .then(() => {
        SuccessToast("Wrong Load/Wrong Tag item added successfully!");
        navigate(`/baggage-management/wrongload-wrongtag/list`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleTransitChange = (e) => {
    setShowTransit(e.target.checked);
    formMethods.setValue("hasTransit", e.target.checked);
  };

  return (
    <>
      <BreadCrumbs
        pageName="addWrongLoadWrongTag"
        pageTitle="Add Wrong Load/Wrong Tag"
      />
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Card>
                <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                  <CardTitle tag="h1" className="fw-bolder">
                    Add Wrong Load/Wrong Tag Item
                  </CardTitle>
                  <div>
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      color="primary"
                      className="me-2"
                    >
                      Save
                    </LoadingButton>
                    <Button
                      type="button"
                      color="warning"
                      onClick={() => {
                        navigate(`/baggage-management/wrongload-wrongtag/list`);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardHeader>

                <CardBody>
                  <div className="info-container">
                    <br />
                    <Row>
                      <CardTitle tag="h5">
                        <Col md={2} className="fw-bolder border-bottom pb-50">
                          <span className="section-label">Bag Sector</span>
                        </Col>
                      </CardTitle>
                      <Col md={3} className="mb-1">
                        <TextInput
                          twoLines
                          label="Flight Number"
                          name="flightNumber"
                          icon={faPlane}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <CalendarInput
                          twoLines
                          label="Flight Date"
                          name="flightDate"
                          icon={faCalendar}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <SelectInput
                          twoLines
                          label="Destination"
                          name="destination"
                          options={
                            stationList?.map((station) => ({
                              value: station.id,
                              label: station.id,
                            })) || []
                          }
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <SelectInput
                          twoLines
                          label="Fault Station"
                          name="faultStation"
                          options={
                            stationList?.map((station) => ({
                              value: station.id,
                              label: station.id,
                            })) || []
                          }
                        />
                      </Col>
                      <CardTitle tag="h5">
                        <Col
                          md={2}
                          className="fw-bolder border-bottom pb-50 mt-1"
                        >
                          <span className="section-label">
                            Passenger Sector
                          </span>
                        </Col>
                      </CardTitle>
                      <Col md={3} className="mb-1">
                        <TextInput
                          twoLines
                          label="Flight Number"
                          name="paxToFlightNumber"
                          icon={faPlane}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <CalendarInput
                          twoLines
                          label="Flight Date"
                          name="paxToFlightDate"
                          icon={faCalendar}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <SelectInput
                          twoLines
                          label="Checked To"
                          name="paxCheckedTo"
                          options={
                            stationList?.map((station) => ({
                              value: station.id,
                              label: station.id,
                            })) || []
                          }
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <TextInput
                          twoLines
                          label="Booking Reference"
                          name="paxBookingReference"
                          icon={faComment}
                        />
                      </Col>
                      <Col xs={12} className="mt-2 mb-2">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            id="hasTransit"
                            onChange={handleTransitChange}
                            checked={showTransit}
                          />
                          <Label className="form-check-label" for="hasTransit">
                            Has Transit
                          </Label>
                        </div>
                      </Col>
                      {showTransit && (
                        <>
                          <Col md={3} className="mb-1">
                            <SelectInput
                              twoLines
                              label="Transit Station"
                              name="transitStation"
                              options={
                                stationList?.map((station) => ({
                                  value: station.id,
                                  label: station.id,
                                })) || []
                              }
                            />
                          </Col>

                          <Col md={3} className="mb-1">
                            <TextInput
                              twoLines
                              label="Transit Flight Number"
                              name="transitFlightNumber"
                              icon={faPlane}
                            />
                          </Col>

                          <Col md={3} className="mb-1">
                            <CalendarInput
                              twoLines
                              label="Transit Flight Date"
                              name="transitFlightDate"
                              icon={faCalendar}
                            />
                          </Col>
                        </>
                      )}
                      <CardTitle tag="h5">
                        <Col
                          md={2}
                          className="fw-bolder border-bottom pb-50 mt-1"
                        >
                          <span className="section-label">
                            Other Information
                          </span>
                        </Col>
                      </CardTitle>
                      <Col md={3} className="mb-1">
                        <SelectInput
                          twoLines
                          label="Status"
                          name="status"
                          options={[
                            { value: "wrl", label: "Wrong Load" },
                            { value: "wrt", label: "Wrong Tag" },
                          ]}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <TextInput
                          twoLines
                          label="Total Pieces"
                          name="totalPieces"
                          type="number"
                          icon={faBoxes}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <TextInput
                          twoLines
                          label="OHD"
                          name="ohd"
                          icon={faComment}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <TextInput
                          twoLines
                          label="Claim Number"
                          name="claimNumber"
                          icon={faComment}
                        />
                      </Col>
                      <Col md={3} className="mb-1">
                        <TextInput
                          twoLines
                          label="Tag Number"
                          name="tagNumber"
                          icon={faComment}
                        />
                      </Col>
                      {/* Conditionally Render WRT Fields */}
                      {status === "wrt" && (
                        <>
                          <Col md={3} className="mb-1">
                            <TextInput
                              twoLines
                              label="WRT Tag Number"
                              name="wrtTagNumber"
                              icon={faComment}
                            />
                          </Col>
                          <Col md={3} className="mb-1">
                            <TextInput
                              twoLines
                              label="WRT Tag Booking Reference"
                              name="wrtTagBookingReference"
                              icon={faComment}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      <Col md={8} className="mb-1">
                        <TextInput
                          twoLines
                          label="Comments"
                          name="comments"
                          icon={faComment}
                          type="textarea"
                          rows="5"
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="12" md="12" xs="12">
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle tag="h5" className="mb-0">
                    <span className="section-label">Upload Files</span>{" "}
                    <span className="font-small-2 text-muted">
                      {" "}
                      (One file at least)
                    </span>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <UploadFile
                    files={files}
                    setFiles={setFiles}
                    formMethods={formMethods}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddWrongLoadWrongTag;
