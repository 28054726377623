import React, { useState, useRef } from "react";
import { Camera } from "lucide-react";
import { Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CameraCapture = ({ setImageFile, ...props }) => {
  const [stream, setStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [capturedFile, setCapturedFile] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0);
      const imageData = canvasRef.current.toDataURL("image/png");
      setCapturedImage(imageData);

      stopCamera();
    }
  };

  const addFile = () => {
    // Convert base64 to blob
    const base64Data = capturedImage.split(",")[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    // Create File object
    const file = new File([blob], "captured-image.png", {
      type: "image/png",
    });
    setCapturedFile(file);
    setImageFile(file);
    toggleModal();
    setCapturedImage(null);
  };

  const retake = () => {
    setCapturedImage(null);
    setCapturedFile(null);
    setImageFile(null);
    startCamera();
  };

  return (
    <>
      {!stream && !capturedImage && (
        <Button
          color="warning"
          onClick={() => {
            toggleModal();
            startCamera();
          }}
          {...props}
        >
          <Camera style={{ width: "24px", height: "16px" }} />
        </Button>
      )}
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          stopCamera();
          toggleModal();
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            stopCamera();
            toggleModal();
          }}
        ></ModalHeader>
        <ModalBody>
          {stream && (
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className="w-full h-full object-cover"
            />
          )}
          {capturedImage && (
            <img
              src={capturedImage}
              alt="Captured"
              className="w-full h-full object-cover"
            />
          )}

          <canvas ref={canvasRef} className="hidden" />
        </ModalBody>
        <ModalFooter>
          {stream && (
            <>
              <Button color="primary" onClick={captureImage}>
                Capture
              </Button>
              <Button
                color="warning"
                onClick={() => {
                  stopCamera();
                  toggleModal();
                }}
              >
                Stop Camera
              </Button>
            </>
          )}
          {capturedImage && (
            <>
              <Button color="primary" onClick={retake}>
                Retake
              </Button>
              <Button variant="outline" onClick={() => addFile()}>
                Add
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CameraCapture;
