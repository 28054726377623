import { Dropdown } from "primereact/dropdown";
import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faUsers,
  faUserTie,
  faBoxesPacking,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const getMessageType = (type) => {
  switch (type) {
    case "ldm":
      return faBoxesPacking;
    case "prl":
      return faUsers;
    case "apis":
      return faUserTie;
  }
};
const iconMapping = {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faUsers,
  faUserTie,
  faBoxesPacking,
};

const actionsBodyTemplate = (
  rowData,
  handleEditClick,
  configureMessageType
) => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        tooltip="Edit"
        tooltipOptions={{ position: "top" }}
        severity="secondary"
        text
        onClick={() => {
          handleEditClick({ ...rowData });
        }}
      >
        <span className="text-capitalize">
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ fontSize: "1.3rem" }}
          />
        </span>
      </Button>
      <Button
        tooltip="Configurations"
        tooltipOptions={{ position: "top" }}
        severity="secondary"
        text
        onClick={() => {
          configureMessageType(rowData.id);
        }}
      >
        <span className="text-capitalize">
          <FontAwesomeIcon icon={faGear} style={{ fontSize: "1.3rem" }} />
        </span>
      </Button>
    </>
  );
};

const messageTypesColumns = (handleEditClick, configureMessageType) => {
  return [
    {
      header: "Code",
      filter: true,
      field: "code",
      sortable: false,
    },
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, configureMessageType),
    },
  ];
};

export default messageTypesColumns;
