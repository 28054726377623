import toast from 'react-hot-toast';

export const handleApiError = (error, defaultMessage = 'An unknown error occurred') => {
    // Handle validation errors from response data

    if (error?.data?.errors) {
        const validationErrors = error.data?.errors ?? error?.errors ?? [];

        if (validationErrors instanceof Array) {
            validationErrors.forEach(err => {
                toast.error(err.errorMessage);
            });
        }
        return validationErrors;
    }

    // Handle validation errors directly in data
    if (error.errors) {
        error.errors.forEach(err => {
            toast.error(err.errorMessage);
        });
        return error.errors;
    }

    // Handle general errors
    const errorMessage = error?.data?.errorMessage || error?.errorMessage || error?.message || defaultMessage;
    toast.error(errorMessage);
    return null;
};
