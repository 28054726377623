import {
  faQuestionCircle,
  faExclamationTriangle,
  faBan,
  faClock,
  faMoneyBill,
  faHammer,
  faHome,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

export const caseTypeOptions = [
  { value: "AHL", label: "AHL" },
  { value: "DPR", label: "DPR" },
];

export const subTypeOptions = [
  { value: "DMG", label: "DMG" },
  { value: "PIL", label: "PIL" },
  { value: "FINAL", label: "FINAL" },
  { value: "DELAY", label: "DELAY" },
];

export const flightTypeOptions = [
  { value: "domestic", label: "Domestic" },
  { value: "international", label: "International" },
];

export const bankAccountTypeOptions = [
  { value: "Local_Account", label: "Local Account" },
  { value: "International_Account", label: "International Account" },
];

// Helper function to get filtered sub type options based on case type
export const getFilteredSubTypeOptions = (caseType) => {
  if (caseType === "DPR") {
    return subTypeOptions.filter((option) =>
      ["DMG", "PIL"].includes(option.value)
    );
  } else if (caseType === "AHL") {
    return subTypeOptions.filter((option) =>
      ["DELAY", "FINAL"].includes(option.value)
    );
  }
  return [];
};

export const caseStatuses = [
  { label: "Pending", value: "pending" },
  { label: "Transferred To CSU", value: "transferredToCSU" },
  { label: "In Progress", value: "inProgress" },
  { label: "Closed", value: "closed" },
  { label: "Rejected", value: "rejected" },
];

export const getStatusSeverity = (status) => {
  switch (status) {
    case "pending":
      return "warning";
    case "transferredToCSU":
      return "info";
    case "inProgress":
      return "primary";
    case "closed":
      return "success";
    case "rejected":
      return "danger";
    default:
      return null;
  }
};

export const getCaseTypeIcon = (type) => {
  switch (type?.toLowerCase()) {
    case "ahl":
      return faQuestionCircle;
    case "dpr":
      return faExclamationTriangle;
    default:
      return faQuestionCircle;
  }
};

export const getSubTypeIcon = (type) => {
  switch (type?.toLowerCase()) {
    case "dmg":
      return faHammer;
    case "pil":
      return faBan;
    case "final":
      return faMoneyBill;
    case "delay":
      return faClock;
    default:
      return faQuestionCircle;
  }
};

export const getFlightTypeIcon = (type) => {
  switch (type?.toLowerCase()) {
    case "domestic":
      return faHome;
    case "international":
      return faGlobe;
    default:
      return null;
  }
};
