import PnlMessages from ".";

const PnlMessagesRoutes = [
  {
    path: "/pnl-messages",
    index: true,
    element: <PnlMessages />,
  },
];

export default PnlMessagesRoutes;
