import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { formatDateOnly } from "@src/utility/Utils";
import {
  faEye,
  faCheck,
  faXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  UncontrolledTooltip,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import {
  caseTypeOptions,
  subTypeOptions,
  getStatusSeverity,
  getCaseTypeIcon,
  getSubTypeIcon,
  caseStatuses,
} from "./constants";
import { MoreVertical, Edit } from "react-feather";

const CompensationColumns = (handleAcceptClick, handleRejectClick) => {
  const dateBodyTemplate = (rowData, field) =>
    rowData[field] ? formatDateOnly(new Date(rowData[field])) : "-";

  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.caseStatus}
        severity={getStatusSeverity(rowData.caseStatus)}
        className="text-capitalize"
      />
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={caseStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
        itemTemplate={statusItemTemplate}
      />
    );
  };

  const statusItemTemplate = ({ label, value }) => {
    return (
      <Tag
        value={label}
        severity={getStatusSeverity(value)}
        className="text-capitalize"
      />
    );
  };

  const caseTypeBodyTemplate = (rowData) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getCaseTypeIcon(rowData.caseType)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {rowData.caseType}
      </span>
    );
  };

  const caseTypeItemTemplate = ({ label, value }) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getCaseTypeIcon(value)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {label}
      </span>
    );
  };

  const caseTypeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={caseTypeOptions}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Select Type"
        className="p-column-filter"
        showClear
        itemTemplate={caseTypeItemTemplate}
      />
    );
  };

  const subTypeBodyTemplate = (rowData) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getSubTypeIcon(rowData.subType)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {rowData.subType}
      </span>
    );
  };

  const subTypeItemTemplate = ({ label, value }) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getSubTypeIcon(value)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {label}
      </span>
    );
  };

  const subTypeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={subTypeOptions}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Select Sub Type"
        className="p-column-filter"
        showClear
        itemTemplate={subTypeItemTemplate}
      />
    );
  };

  const actionsBodyTemplate = (row, handleAcceptClick, handleRejectClick) => (
    <div className="d-flex justify-space-between">
      <a
        id={`details-${row.id}`}
        className="btn btn-icon rounded-circle btn-outline-dark me-1"
        href={`/baggage-management/compensation/list/${row.id}`}
      >
        <FontAwesomeIcon icon={faEye} className="font-medium-3" />
      </a>
      <UncontrolledTooltip target={`details-${row.id}`}>
        Details
      </UncontrolledTooltip>
      {row.caseStatus == "pending" ? (
        <UncontrolledDropdown>
          <DropdownToggle className="pe-1" tag="span">
            <Button
              className="btn-icon rounded-circle ms-1"
              outline
              color="dark"
            >
              <MoreVertical size={15} />
            </Button>
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem
              href={`/baggage-management/compensation/list/${row.id}/update`}
              tag="a"
            >
              <FontAwesomeIcon icon={faEdit} className="font-medium-3" />
              <span className="align-middle ms-50">Update</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={() => {
                handleRejectClick({ ...row });
              }}
            >
              <FontAwesomeIcon icon={faXmark} className="font-medium-3" />
              <span className="align-middle ms-50">Reject</span>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleAcceptClick({ ...row });
              }}
            >
              <FontAwesomeIcon icon={faCheck} className="font-medium-3" />
              <span className="align-middle ms-50">Accept</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <>
          <a
            id={`update-${row.id}`}
            className="btn btn-icon rounded-circle  ms-1  btn-outline-primary"
            href={`/baggage-management/compensation/list/${row.id}/update`}
          >
            <Edit size={15} />
          </a>
          <UncontrolledTooltip target={`update-${row.id}`}>
            Update
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="No" field="id" filter rowSpan={2} dataType="numeric" />
        <Column header="Case Info" colSpan={4} className="centered-header" />
        <Column header="Claim" colSpan={2} className="centered-header" />
        <Column header="Station" field="station" filter rowSpan={2} />
        <Column header="Tag No" field="tagNo" filter rowSpan={2} />
        <Column header="PNR" field="pnr" filter rowSpan={2} />
        <Column
          header="Transfer to CS Date"
          field="transferToCSDate"
          rowSpan={2}
        />
        <Column
          header="Transfer to Finance Date"
          field="transferToFinanceDate"
          rowSpan={2}
        />
        <Column
          header="Close Date"
          field="closeDate"
          filter
          filterElement={dateFilterTemplate}
          rowSpan={2}
          dataType="date"
        />
        <Column
          header="Created Date"
          field="createdOnUtc"
          filter
          filterElement={dateFilterTemplate}
          rowSpan={2}
          dataType="date"
        />
        <Column header="Actions" rowSpan={2} />
      </Row>
      <Row>
        <Column header="Code" field="caseCode" filter />
        <Column
          header="Type"
          field="caseType"
          filter
          filterElement={caseTypeFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column
          header="Sub Type"
          field="subType"
          filter
          filterElement={subTypeFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column
          header="Status"
          field="caseStatus"
          filter
          filterElement={statusFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column header="No" field="claimNumber" filter dataType="numeric" />
        <Column
          header="Date"
          field="claimCreatedDate"
          filter
          filterElement={dateFilterTemplate}
          dataType="date"
        />
      </Row>
    </ColumnGroup>
  );

  return {
    bodyColumns: [
      { header: "No", field: "id", filter: true, dataType: "numeric" },
      { header: "Case Code", field: "caseCode", filter: true },
      {
        header: "Type",
        field: "caseType",
        filter: true,
        showFilterMatchModes: false,
        body: caseTypeBodyTemplate,
      },
      {
        header: "Sub Type",
        field: "subType",
        filter: true,
        showFilterMatchModes: false,
        body: subTypeBodyTemplate,
      },
      {
        header: "Status",
        field: "caseStatus",
        filter: true,
        body: statusBodyTemplate,
        showFilterMatchModes: false,
      },
      {
        header: "Claim No",
        field: "claimNumber",
        filter: true,
      },
      {
        header: "Claim Created",
        field: "claimCreatedDate",
        filter: true,
        body: (rowData) => dateBodyTemplate(rowData, "claimCreatedDate"),
      },
      { header: "Station", field: "station", filter: true },
      { header: "Tag No", field: "tagNo", filter: true },
      { header: "PNR", field: "pnr", filter: true },
      {
        header: "Transfer to CS",
        field: "transferToCSDate",
        filter: true,
        body: (rowData) => dateBodyTemplate(rowData, "transferToCSDate"),
      },
      {
        header: "Transfer to Finance",
        field: "transferToFinanceDate",
        filter: true,
        body: (rowData) => dateBodyTemplate(rowData, "transferToFinanceDate"),
      },
      {
        header: "Close Date",
        field: "closeDate",
        filter: true,
        body: (rowData) => dateBodyTemplate(rowData, "closeDate"),
      },

      {
        header: "Created Date",
        field: "createdOnUtc",
        filter: true,
        body: (rowData) => dateBodyTemplate(rowData, "createdOnUtc"),
      },
      {
        header: "Actions",
        body: (rowData) =>
          actionsBodyTemplate(rowData, handleAcceptClick, handleRejectClick),
        headerStyle: { textAlign: "center" },
      },
    ],
    headerGroup,
  };
};

export default CompensationColumns;
