import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import { handleApiError } from '@src/utility/errorHandler';

export const seatConfigurationApi = createApi({
    reducerPath: 'seatConfigurationApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['SeatConfiguration'],
    endpoints: (builder) => ({
        getNewNotifications: builder.query({
            query: (params) => ({
                url: '/api/seat-configuration/notifications-new',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                return {
                    items: response.items || [],
                    metadata: response.metadata || {
                        pageCount: 0,
                        totalItemCount: 0,
                        pageNumber: 1,
                        pageSize: 10,
                        hasPreviousPage: false,
                        hasNextPage: false,
                        isFirstPage: false,
                        isLastPage: false,
                        firstItemOnPage: 0,
                        lastItemOnPage: 0
                    }
                };
            },
            providesTags: ['SeatConfiguration'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch new notifications');
                }
            }
        }),
        getHistoryNotifications: builder.query({
            query: (params) => ({
                url: '/api/seat-configuration/notifications-history',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                return {
                    items: response.items || [],
                    metadata: response.metadata || {
                        pageCount: 0,
                        totalItemCount: 0,
                        pageNumber: 1,
                        pageSize: 10,
                        hasPreviousPage: false,
                        hasNextPage: false,
                        isFirstPage: false,
                        isLastPage: false,
                        firstItemOnPage: 0,
                        lastItemOnPage: 0
                    }
                };
            },
            providesTags: ['SeatConfiguration'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch history notifications');
                }
            }
        })
    })
});

export const {
    useGetNewNotificationsQuery,
    useGetHistoryNotificationsQuery
} = seatConfigurationApi; 