import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useRejectCompensationItemMutation } from "@src/redux/compensation/list";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";

const RejectModal = ({ visible, toggle, data }) => {
  const [rejectCompensation, { isLoading }] =
    useRejectCompensationItemMutation();
  const rejectCompensationSchema = yup.object().shape({
    rejectionReason: yup.string().required(),
  });

  const formMethods = useForm({
    resolver: yupResolver(rejectCompensationSchema),
    mode: "onSubmit",
    defaultValues: {
      rejectionReason: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        rejectionReason: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await rejectCompensation({
      id: data.id,
      rejectionReason: formData.rejectionReason,
    })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Compensation rejected successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Reject Compensation</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <TextInput
                    twoLines={true}
                    label="Rejection Reason"
                    name="rejectionReason"
                    icon={faTag}
                    type="textarea"
                    rows="5"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default RejectModal;
