import React from "react";
import { Card, CardHeader, Button, ButtonGroup } from "reactstrap";
import { faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteWrongLoadWrongTageItemMutation } from "@src/redux/wrongload-wrongtag/list";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const ActionToolbar = ({ item }) => {
  const navigate = useNavigate();
  const [deleteWrongLoadWrongTag] = useDeleteWrongLoadWrongTageItemMutation();

  const handleDeleteClick = async (id) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this Wrong Load/Tag item?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteWrongLoadWrongTag(id)
          .unwrap()
          .then((fulfilled) => {
            SuccessToast("Wrong Load/Tag item deleted Successfully!");
            navigate(`/baggage-management/wrongload-wrongtag/list`);
            toggle(false);
          })
          .catch((rejected) => {});
      },
    });
  };

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom p-1 flex flex-wrap gap-2"
          style={{ justifyContent: "right" }}
        >
          <ButtonGroup className="mb-1">
            <Button
              outline
              type="button"
              color="secondary"
              onClick={() => handleDeleteClick(item.id)}
            >
              <FontAwesomeIcon icon={faXmark} className="font-medium-1" />
              &nbsp; Delete
            </Button>
            <Button
              outline
              tag={Link}
              to={`/baggage-management/wrongload-wrongtag/list/${item.id}/update`}
              type="button"
              color="secondary"
            >
              <FontAwesomeIcon icon={faEdit} className="font-medium-1" />
              &nbsp; Update
            </Button>
          </ButtonGroup>
        </CardHeader>
      </Card>
    </>
  );
};

export default ActionToolbar;
