import React, { useState, useEffect } from "react";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetWrongLoadWrongTagListQuery,
  useExportWrongLoadWrongTagItemsMutation,
  useDeleteWrongLoadWrongTageItemMutation,
} from "@src/redux/wrongload-wrongtag/list";
import WrongLoadWrongTagColumns from "./columns";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import Export from "../common/Export";
import { useNavigate } from "react-router-dom";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const WrongLoadWrongTagList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    flightNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    flightDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    destination: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    faultStation: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    paxToFlightNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    paxToFlightDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    paxCheckedTo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ohd: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    tagNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    claimNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetWrongLoadWrongTagListQuery(filters);
  const [exportWrongLoadWrongTag, { isLoading: isLoadingExport }] =
    useExportWrongLoadWrongTagItemsMutation();
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const [deleteWrongLoadWrongTag] = useDeleteWrongLoadWrongTageItemMutation();

  useEffect(() => {
    setIsExportDisabled(!data?.items || data.items.length === 0);
  }, [data?.items]);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportWrongLoadWrongTag}
      exportedEntity="Wrong Load/Tag"
      isLoading={isLoadingExport}
      disabled={isExportDisabled}
    />
  );

  const handleDeleteClick = async (item) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this Wrong Load/Tag Item?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteWrongLoadWrongTag(item.id)
          .unwrap()
          .then((fulfilled) => {
            SuccessToast("Wrong Load/Tag Item deleted Successfully!");
            toggle(false);
          })
          .catch((rejected) => {});
      },
    });
  };

  const { bodyColumns, headerGroup } =
    WrongLoadWrongTagColumns(handleDeleteClick);

  return (
    <>
      <BreadCrumbs
        pageName="WrongLoadWrongTagList"
        pageTitle="Wrong Load/Tag Search"
      />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={bodyColumns}
            headerGroup={headerGroup}
            values={data?.items || []}
            filters={dtFilters}
            actionTemplate={actionTemplate}
            setTableState={setFilters}
            clearFilter={clearFilters}
            tableState={filters}
            emptyMessage="No Wrong Load/Tag found..."
            displayAdd={true}
            handleAdd={() => {
              navigate(`/baggage-management/wrongload-wrongtag/list/add`);
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default WrongLoadWrongTagList;
