import { mainApi } from "../mainApi";

export const GroundHandlerApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroundHandlerList: builder.query({
      query: () => ({
        url: `/groundhandler/list`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetGroundHandlerListQuery } = GroundHandlerApi;
