// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { mealApi } from './meals';
import { mealCycleApi } from './meal-cycle';
import { dutyApi } from './duties';
import { reportsApi } from './reports';
import { crewApi } from './crew';
import { seatConfigurationApi } from './seatConfiguration';
import { setupListeners } from "@reduxjs/toolkit/query";
// const store = configureStore({
//   reducer: {
//     ...rootReducer,
//     [seatConfigurationApi.reducerPath]: seatConfigurationApi.reducer
//   },
//   middleware: (getDefaultMiddleware) => {
//     return getDefaultMiddleware().concat(
//       mealApi.middleware,
//       mealCycleApi.middleware,
//       dutyApi.middleware,
//       reportsApi.middleware,
//       crewApi.middleware,
//       seatConfigurationApi.middleware
//     );
//   },
// });

// export { store };

const middlewares = [
  mealApi.middleware,
  mealCycleApi.middleware,
  dutyApi.middleware,
  reportsApi.middleware,
  crewApi.middleware,
  seatConfigurationApi.middleware
];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {

    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares);
  },
});
setupListeners(store.dispatch)
export { store };
