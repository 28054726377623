import React, { useState } from "react";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetcompensationListQuery,
  useAcceptCompensationItemMutation,
} from "@src/redux/compensation/list";
import CompensationColumns from "./columns";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import RejectModal from "./RejectModal";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const CompensationList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    caseCode: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    caseType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    subType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    caseStatus: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    claimNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    tagNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    pnr: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    createdOnUtc: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    claimCreatedDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    transferToCSDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    transferToFinanceDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    closeDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetcompensationListQuery(filters);
  const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
  const [compensationData, setCompensationData] = useState(null);
  const [acceptCompensation] = useAcceptCompensationItemMutation();

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const handleRejectClick = (data) => {
    setCompensationData(data);
    setRejectModalVisibility(true);
  };

  const handleAcceptClick = async (item) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Accept this Compensation?",
      icon: "question",
      confirmButtonText: "Accept",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await acceptCompensation({ id: item.id })
          .unwrap()
          .then((fulfilled) => {
            SuccessToast("Compensation accepted Successfully!");
            navigate(`/baggage-management/compensation/list`);
          })
          .catch((rejected) => {});
      },
    });
  };

  const { bodyColumns, headerGroup } = CompensationColumns(
    handleAcceptClick,
    handleRejectClick
  );
  return (
    <>
      <BreadCrumbs
        pageName="CompensationList"
        pageTitle="Compensations Search"
      />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={bodyColumns}
            headerGroup={headerGroup}
            values={data?.items || []}
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            tableState={filters}
            emptyMessage="No Compensation found..."
            displayAdd={true}
            handleAdd={() => {
              navigate(`/baggage-management/compensation/list/add`);
            }}
          />
        </CardBody>
      </Card>
      <RejectModal
        visible={rejectModalVisibility}
        toggle={() => setRejectModalVisibility(false)}
        data={compensationData}
      />
    </>
  );
};

export default CompensationList;
