import { api } from '@app/layout'

api.defaults.baseURL = process.env.REACT_APP_API_URL

export const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async ({ url, method, data, params, headers, responseType }) => {
            try {
                const result = await api({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    headers,
                    responseType
                })
                return { data: result.data }
            } catch (axiosError) {
                const err = axiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }



