import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import toast from 'react-hot-toast';
import { handleApiError } from '@src/utility/errorHandler';

export const mealApi = createApi({
    reducerPath: 'mealApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Meal', 'MealTimeRange'],
    endpoints: (builder) => ({
        getMeals: builder.query({
            query: () => ({
                url: '/api/meal/list',
                method: 'GET'
            }),
            transformResponse: (response) => {
                const data = response;
                return { items: data };
            },
            // Enable automatic re-fetching when mutations happen
            providesTags: ['Meal'],
            // Handle errors
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch meals');
                }
            }
        }),

        addMeal: builder.mutation({
            query: (mealData) => ({
                url: '/api/meal',
                method: 'POST',
                data: mealData,
            }),
            transformResponse: (response) => response.data,
            // Invalidate the Meal tag to trigger a refetch
            invalidatesTags: ['Meal'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal added successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to add meal');
                }
            }
        }),

        deleteMeal: builder.mutation({
            query: (mealId) => ({
                url: `/api/meal/${mealId}`,
                method: 'DELETE',
            }),
            // Invalidate the Permission tag to trigger a refetch
            invalidatesTags: ['Meal'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal deleted successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to delete meal');
                }
            }
        }),

        getMealById: builder.query({
            query: (id) => ({
                url: `/api/meal/${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['Meal'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch meal');
                }
            }
        }),
        updateMeal: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/meal/${id}`,
                method: 'PUT',
                data,
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Meal'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal updated successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to update meal');
                }
            }
        }),
        getMealTimeRange: builder.query({
            query: () => ({
                url: '/api/meal/favorites-range',
                method: 'GET',
            }),
            // providesTags: ['MealTimeRange'],
            // async onQueryStarted(_, { queryFulfilled }) {
            //     try {
            //         await queryFulfilled;
            //     } catch (error) {
            //         handleApiError(error.error, 'Failed to fetch meal time range');
            //     }
            // }
        }),
        updateMealTimeRangeWithDuration: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/meal/favorites-range/${id}`,
                method: 'PUT',
                data: {
                    id,
                    ...data,
                },
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['MealTimeRange'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Meal time range updated successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to update meal time range');
                }
            }
        }),
        getFavorites: builder.query({
            query: () => ({
                url: '/api/meal/favorites',
                method: 'GET'
            }),
            providesTags: ['Meal'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch favorites');
                }
            }
        }),
        addFavorite: builder.mutation({
            query: (data) => ({
                url: '/api/meal/favorites',
                method: 'POST',
                data
            }),
            invalidatesTags: ['Meal'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Favorite added successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to add favorite');
                }
            }
        }),
        deleteFavorite: builder.mutation({
            query: (id) => ({
                url: `/api/meal/favorites/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Meal'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Favorite removed successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to remove favorite');
                }
            }
        })
    }),
});

// Export hooks for usage in components
export const {
    useGetMealsQuery,
    useAddMealMutation,
    useDeleteMealMutation,
    useGetMealByIdQuery,
    useUpdateMealMutation,
    useGetMealTimeRangeQuery,
    useUpdateMealTimeRangeWithDurationMutation,
    useGetFavoritesQuery,
    useAddFavoriteMutation,
    useDeleteFavoriteMutation
} = mealApi;
