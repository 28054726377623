import { getTruncatedDescription } from "@src/utility/Utils";
import { Dropdown } from "primereact/dropdown";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const itemTypeColumns = () => {
  const DescriptionBodyTemplate = ({ description }) => (
    <span>{getTruncatedDescription(description)}</span>
  );

  const IsValuableStatuses = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const getIsValuableStatusIcon = (isValuable) =>
    isValuable === "true" ? faCircleCheck : faCircleXmark;

  const getIsValuableStatusClass = (isValuable) =>
    isValuable === true ? "text-success" : "text-danger";

  const IsValuableStatusBodyTemplate = ({ isValuable }) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getIsValuableStatusIcon(isValuable)}
        className={getIsValuableStatusClass(isValuable)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {isValuable == true ? "Yes" : "No"}
    </span>
  );

  const IsValuableStatusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={IsValuableStatuses}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={IsValuableStatusItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );

  const IsValuableStatusItemTemplate = ({ label, value }) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getIsValuableStatusIcon(value)}
        className={getIsValuableStatusClass(value)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {label}
    </span>
  );

  return [
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
    },
    {
      header: "Is Valuable",
      filter: true,
      field: "isValuable",
      sortable: false,
      body: IsValuableStatusBodyTemplate,
      filterElement: IsValuableStatusFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Description",
      filter: false,
      field: "description",
      sortable: false,
      body: DescriptionBodyTemplate,
    },
  ];
};

export default itemTypeColumns;
