import React, { useState, useEffect } from "react";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetLzBaggageListQuery,
  useExportLzBaggageItemsMutation,
} from "@src/redux/lz-baggage/list";
import lzBaggageColumns from "./columns";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import Export from "../../common/Export";
import { useNavigate } from "react-router-dom";
import DeliverModal from "./deliverModel";
import ForwardModal from "./forward";
import DestroyModal from "./destroy";

const LZBaggageList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    module: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    moduleCreatedDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    moduleNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    originalFlightNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    destination: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    receivedFlightNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    receivedFlightDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    tagNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    bagTypeColor: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    rushTag: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    receivedDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetLzBaggageListQuery(filters);
  const [exportLzBaggageItems, { isLoading: isLoadingExportedLzBaggageItems }] =
    useExportLzBaggageItemsMutation();
  const [deliverModalVisibility, setDeliverModalVisibility] = useState(false);
  const [forwardModalVisibility, setForwardModalVisibility] = useState(false);
  const [destroyModalVisibility, setDestroyModalVisibility] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  useEffect(() => {
    setIsExportDisabled(!data?.items || data.items.length === 0);
  }, [data?.items]);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportLzBaggageItems}
      exportedEntity={"LzBaggageItems"}
      isLoading={isLoadingExportedLzBaggageItems}
      disabled={isExportDisabled}
    />
  );

  const handleDeliverClick = (item) => {
    setItemData(item);
    setDeliverModalVisibility(true);
  };
  const handleForwardClick = (item) => {
    setItemData(item);
    setForwardModalVisibility(true);
  };
  const handleDestroyClick = (item) => {
    setItemData(item);
    setDestroyModalVisibility(true);
  };

  const { bodyColumns, headerColumns } = lzBaggageColumns(
    handleDeliverClick,
    handleForwardClick,
    handleDestroyClick
  );

  return (
    <>
      <BreadCrumbs pageName="lzBaggageList" pageTitle="LZ Baggage List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={bodyColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No LZ Baggage items found..."
            filters={dtFilters}
            actionTemplate={actionTemplate}
            setTableState={setFilters}
            clearFilter={clearFilters}
            tableState={filters}
            displayAdd={true}
            handleAdd={() => {
              navigate(`/baggage-management/lz-baggage/list/add`);
            }}
            headerGroup={headerColumns}
          />
        </CardBody>
      </Card>
      <DeliverModal
        isVisible={deliverModalVisibility}
        toggle={() => setDeliverModalVisibility(false)}
        baggageData={itemData}
      />
      <ForwardModal
        isVisible={forwardModalVisibility}
        toggle={() => setForwardModalVisibility(false)}
        baggageData={itemData}
      />
      <DestroyModal
        isVisible={destroyModalVisibility}
        toggle={() => setDestroyModalVisibility(false)}
        baggageData={itemData}
      />
    </>
  );
};

export default LZBaggageList;
