import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useLazyGetMessageTypeQuery } from "@src/redux/MessageTypes";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect, useState } from "react";
import { TextInput, CheckboxInput } from "@src/components/inputs";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectInput } from "@src/components/inputs";
import { useConfigureMessageTypesMutation } from "@src/redux/MessageTypes";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const Configurations = ({ visible, toggle, messageTypeId }) => {
  const [messageType, setMessageType] = useState(null);
  const [getMessageType] = useLazyGetMessageTypeQuery();
  const [configureMessageTypes] = useConfigureMessageTypesMutation();

  const sendingTriggerOptions = [
    { label: "Before Departure", value: "beforeDeparture" },
    { label: "After Departure", value: "afterDeparture" },
  ];

  const validationSchema = yup.object().shape({
    isActive: yup.boolean(),
    enableMultipart: yup.boolean(),
    maxCharactersPerPart: yup.number().when("enableMultipart", {
      is: true,
      then: () =>
        yup
          .number()
          .min(1, "Must be greater than 0 when multipart is enabled")
          .required("Required when multipart is enabled"),
      otherwise: () => yup.number().nullable(),
    }),
    trigger: yup.string().when("isActive", {
      is: true,
      then: () =>
        yup.string().required("Sending trigger is required when active"),
      otherwise: () => yup.string().nullable(),
    }),
    hours: yup.number().when("isActive", {
      is: true,
      then: () =>
        yup
          .number()
          .required("Hours is required when active")
          .min(1, "Hours must be greater than or equal to 1"),
      otherwise: () => yup.number().nullable(),
    }),
  });

  const formMethods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isActive: false,
      enableMultipart: false,
      maxCharactersPerPart: 0,
      trigger: undefined,
      hours: 0,
    },
  });

  const enableMultipart = formMethods.watch("enableMultipart");

  useEffect(() => {
    const fetchMessageType = async () => {
      const data = await getMessageType(messageTypeId).unwrap();
      setMessageType(data);
      formMethods.reset({
        isActive: data?.isActive || false,
        enableMultipart: data?.enableMultipart || false,
        maxCharactersPerPart: data?.maxCharactersPerPart || 0,
        trigger: data?.sendingTimes[0].trigger || undefined,
        hours: data?.sendingTimes[0].hours || 0,
      });
    };
    if (messageTypeId) {
      fetchMessageType();
    }
  }, [visible, formMethods, messageTypeId]);

  const handleFormSubmit = async (formData) => {
    await configureMessageTypes({
      id: messageTypeId,
      isActive: formData.isActive,
      enableMultipart: formData.enableMultipart,
      maxCharactersPerPart: formData.maxCharactersPerPart,
      sendingTimes: [
        {
          id: messageType.sendingTimes[0].id,
          trigger: formData.trigger,
          hours: formData.hours,
        },
      ],
    })
      .unwrap()
      .then(() => {
        SuccessToast("Message configuration updated Successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <ModalHeader toggle={toggle}>
        Message Type ({messageType?.code}) Configurations
      </ModalHeader>
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalBody>
            <Row>
              <Col className="mb-2">
                <CheckboxInput label="Is Active?" name="isActive" />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <CheckboxInput
                  className="mb-2"
                  label="Enable Multipart?"
                  name="enableMultipart"
                />
                {enableMultipart && (
                  <TextInput
                    label="Max Characters Per Part"
                    name="maxCharactersPerPart"
                    icon={faHashtag}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <SelectInput
                  label="Sending Trigger"
                  name="trigger"
                  icon={faHashtag}
                  options={sendingTriggerOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput label="Hours" name="hours" icon={faHashtag} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default Configurations;
