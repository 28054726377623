import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import * as yup from "yup";
import { SelectInput, TextInput, ChipsInput } from "@src/components/inputs";
import {
  useAddMessageAddressMutation,
  useUpdateMessageAddressMutation,
} from "@src/redux/messageAddresses";
import { useLazyListMessageTypesQuery } from "@src/redux/MessageTypes";
import {
  faEarthAsia,
  faJetFighterUp,
  faBuilding,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

const MessageAddressModal = ({
  visible,
  toggle,
  editData,
  setEditData,
  mode,
}) => {
  const [updateMessageAddress] = useUpdateMessageAddressMutation();
  const [addMessageAddress] = useAddMessageAddressMutation();
  const [listMessageTypes] = useLazyListMessageTypesQuery();

  const [messageTypes, setMessageTypes] = useState([]);

  const addressTypeOptions = [
    { label: "Sita", value: "sita" },
    { label: "Mail", value: "mail" },
    { label: "SitaMail", value: "sitaMail" },
  ];

  const messageAddressSchema = yup.object().shape({
    country: yup
      .string()
      .nullable()
      .test(
        "len",
        "Country must be 2 characters in length",
        (val) => val?.length === 2 || val?.length === 0
      ),
    station: yup
      .string()
      .nullable()
      .test(
        "len",
        "Station must be 3 characters in length",
        (val) => val?.length === 3 || val?.length === 0
      ),
    address: yup.lazy((value, { parent }) => {
      if (parent.addressType === "sita") {
        return yup
          .array()
          .of(
            yup
              .string()
              .test(
                "len",
                "SITA address must be 7 characters in length",
                (val) => val?.length === 7
              )
          )
          .min(1, "At least one SITA address is required");
      } else {
        return yup
          .array()
          .of(yup.string().email("Must be a valid email"))
          .min(1, "At least one email is required");
      }
    }),
    flightNumber: yup
      .number()
      .typeError("Flight No must be a number")
      .nullable()
      .moreThan(0, "Flight No cannot be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    messageType: yup.string().required("Message type is required"),
    addressType: yup.string().required("Address type is required"),
  });

  const formMethods = useForm({
    resolver: yupResolver(messageAddressSchema),
    mode: "onChange",
    defaultValues: {
      country: "",
      station: "",
      address: [],
      flightNumber: "",
      messageType: "",
      addressType: "",
    },
  });

  const addressType = useWatch({
    control: formMethods.control,
    name: "addressType",
  });

  useEffect(() => {
    if (visible) {
      if (mode === "add") {
        formMethods.reset({
          country: "",
          station: "",
          address: [],
          flightNumber: "",
          messageType: "",
          addressType: "",
        });
      } else {
        const addresses = editData?.address
          ? editData.address.split(";").filter(Boolean)
          : [];

        formMethods.setValue("addressType", editData?.addressType || "");

        formMethods.setValue("country", editData?.country || "");
        formMethods.setValue("station", editData?.station || "");
        formMethods.setValue("address", addresses);
        formMethods.setValue("flightNumber", editData?.flightNumber || "");
        formMethods.setValue("messageType", editData?.messageType || "");
      }
    }
  }, [editData, visible, formMethods, mode]);

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (name === "addressType") {
        formMethods.setValue("address", []);
      }
    });

    return () => subscription.unsubscribe();
  }, [formMethods]);

  useEffect(() => {
    const fetchMessagesList = async () => {
      const list = await listMessageTypes().unwrap();
      setMessageTypes(list);
    };
    fetchMessagesList();
  }, []);

  const handleFormSubmit = async (formData) => {
    const formattedAddress = formData.address.join(";");

    if (mode === "update" && editData) {
      await updateMessageAddress({
        id: editData.id,
        country: formData.country,
        station: formData.station,
        address: formattedAddress,
        flightNumber: formData.flightNumber,
        messageTypeId: formData.messageType,
        addressType: formData.addressType,
      });
    } else {
      await addMessageAddress({
        country: formData.country,
        station: formData.station,
        address: formattedAddress,
        flightNumber: formData.flightNumber,
        messageTypeId: formData.messageType,
        addressType: formData.addressType,
      });
    }
    toggle(false);
    setEditData(null);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Message Address
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Country"
                    name="country"
                    icon={faEarthAsia}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="FlightNo"
                    name="flightNumber"
                    icon={faJetFighterUp}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Station"
                    name="station"
                    icon={faBuilding}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <SelectInput
                    twoLines
                    optionKey="id"
                    optionLabel="code"
                    label="Message Type"
                    name="messageType"
                    options={messageTypes}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <SelectInput
                    twoLines
                    label="Address Type"
                    name="addressType"
                    options={addressTypeOptions}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <ChipsInput
                    twoLines
                    label="Address"
                    name="address"
                    placeholder={
                      addressType === "sita"
                        ? "Enter SITA address (7 characters)"
                        : "Enter email address"
                    }
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save
            </Button>
            <Button color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default MessageAddressModal;
