import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import { handleApiError } from '@src/utility/errorHandler';

export const crewApi = createApi({
    reducerPath: 'crewApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Crew'],
    endpoints: (builder) => ({
        getCrew: builder.query({
            query: (params) => ({
                url: '/api/crew/search',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                return {
                    items: response.items || [],
                    metadata: response.metadata || {
                        pageCount: 0,
                        totalItemCount: 0,
                        pageNumber: 1,
                        pageSize: 10,
                        hasPreviousPage: false,
                        hasNextPage: false,
                        isFirstPage: false,
                        isLastPage: false,
                        firstItemOnPage: 0,
                        lastItemOnPage: 0
                    }
                };
            },
            // Enable automatic re-fetching when mutations happen
            providesTags: ['Crew'],
            // Handle errors
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch crew');
                }
            }
        }),

        getCrewById: builder.query({
            query: (id) => ({
                url: `/api/crew/${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['Crew'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch crew');
                }
            }
        }),
        updateCrew: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/crew/${id}`,
                method: 'PUT',
                data,
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Crew'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success('Crew updated successfully');
                } catch (error) {
                    handleApiError(error.error, 'Failed to update crew');
                }
            }
        }),

    }),
});

// Export hooks for usage in components
export const {
    useGetCrewQuery,
    useGetCrewByIdQuery,
    useUpdateCrewMutation,
} = crewApi;
