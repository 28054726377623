import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import { handleApiError } from '@src/utility/errorHandler';
import { downloadFile } from '@src/utility/index';

export const reportsApi = createApi({
    reducerPath: 'reportsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Reports'],
    endpoints: (builder) => ({
        getSummaryByRank: builder.query({
            query: (params) => ({
                url: '/api/report/summary-by-rank',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                // Transform the response into the format needed for the chart
                return response.map(item => ({
                    date: item['Duty Day'],
                    'Cabin Crew': item['Rank'] === 'Cabin Crew' ? item['Meals'] : 0,
                    'Captain': item['Rank'] === 'Captain' ? item['Meals'] : 0,
                    'First Officer': item['Rank'] === 'First Officer' ? item['Meals'] : 0,
                    'Purser': item['Rank'] === 'Purser' ? item['Meals'] : 0
                }));
            }
        }),

        getSummaryByPrice: builder.query({
            query: (params) => ({
                url: '/api/report/summary-by-price',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                // Transform the response into the format needed for the chart
                return response.map(item => ({
                    date: item['Duty Day'],
                    rank: item['Rank'],
                    'Free Meals': item['Free Meals'] || 0,
                    'Paid Meals': item['Paid Meals'] || 0
                }));
            }
        }),

        getSummaryByStatus: builder.query({
            query: (params) => ({
                url: '/api/report/summary-by-status',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                // Transform the response into the format needed for the pie chart
                return response.map(item => ({
                    name: item['Status'] || 'Not Sent',
                    value: item['Meals']
                }));
            }
        }),

        getDutiesWithRank: builder.query({
            query: (params) => ({
                url: '/api/report/duties-with-rank',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                // Transform the response into the format needed for the table
                return response.map(item => ({
                    dutyDay: item['Duty Day'],
                    epn: item['EPN'],
                    fullName: item['Full Name'],
                    rank: item['Rank'],
                    freeMeals: item['Free Meals'],
                    paidMeals: item['Paid Meals']
                }));
            }
        }),

        getIFSReport: builder.mutation({
            query: (params) => ({
                url: '/api/report/summery-report',
                method: 'POST',
                data: params,
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf'
                }
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    downloadFile(data, `crew_meal_summary_${new Date().toISOString().split('T')[0]}.pdf`, 'application/pdf');
                } catch (error) {
                    handleApiError(error.error, error.error?.status === 404 ? 'No data found for the selected date range' : 'Failed to fetch IFS report');
                }
            }
        }),

        getPaidMealsReport: builder.mutation({
            query: (params) => ({
                url: '/api/report/paid-meals',
                method: 'POST',
                data: params,
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf'
                }
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    downloadFile(data, `paid_meals_${new Date().toISOString().split('T')[0]}.pdf`, 'application/pdf');
                } catch (error) {
                    handleApiError(error.error, error.error?.status === 404 ? 'No data found for the selected date range' : 'Failed to fetch paid meals report');
                }
            }
        }),

        getCateringReport: builder.mutation({
            query: (params) => ({
                url: '/api/report/cataring-report',
                method: 'POST',
                data: params,
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/zip      '
                }
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    downloadFile(data, `catering_report_${new Date().toISOString().split('T')[0]}.zip`, 'application/zip');
                } catch (error) {
                    handleApiError(error.error, error.error?.status === 404 ? 'No data found for the selected date range' : 'Failed to fetch catering report');
                }
            }
        }),

        getDailyMealsReport: builder.mutation({
            query: (params) => ({
                url: '/api/report/meal-status-report-excel',
                method: 'POST',
                data: params,
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    downloadFile(data, `daily_meals_${new Date().toISOString().split('T')[0]}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                } catch (error) {

                    handleApiError(error.error, error.error?.status === 404 ? 'No data found for the selected date range' : 'Failed to fetch daily meals report');
                }
            }
        })
    }),
});

// Export hooks for usage in components
export const {
    useGetSummaryByRankQuery,
    useGetSummaryByPriceQuery,
    useGetSummaryByStatusQuery,
    useGetDutiesWithRankQuery,
    useGetIFSReportMutation,
    useGetPaidMealsReportMutation,
    useGetCateringReportMutation,
    useGetDailyMealsReportMutation
} = reportsApi;
