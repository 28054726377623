import AdlMessages from ".";

const AdlMessagesRoutes = [
  {
    path: "/adl-messages",
    index: true,
    element: <AdlMessages />,
  },
];

export default AdlMessagesRoutes;
