import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';
import { handleApiError } from '@src/utility/errorHandler';

export const dutyApi = createApi({
    reducerPath: 'dutyApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Duty'],
    endpoints: (builder) => ({
        getDuties: builder.query({
            query: (params) => ({
                url: '/api/duty/search',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                return {
                    items: response.items || [],
                    metadata: response.metadata || {
                        pageCount: 0,
                        totalItemCount: 0,
                        pageNumber: 1,
                        pageSize: 10,
                        hasPreviousPage: false,
                        hasNextPage: false,
                        isFirstPage: false,
                        isLastPage: false,
                        firstItemOnPage: 0,
                        lastItemOnPage: 0
                    }
                };
            },
            // Enable automatic re-fetching when mutations happen
            providesTags: ['Duty'],
            // Handle errors
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch duties');
                }
            }
        }),

        getMyDuties: builder.query({
            query: (params) => ({
                url: '/api/duty/get-my-duties',
                method: 'POST',
                data: params
            }),
            transformResponse: (response) => {
                return {
                    items: response.items || [],
                    metadata: response.metadata || {
                        pageCount: 0,
                        totalItemCount: 0,
                        pageNumber: 1,
                        pageSize: 10,
                        hasPreviousPage: false,
                        hasNextPage: false,
                        isFirstPage: false,
                        isLastPage: false,
                        firstItemOnPage: 0,
                        lastItemOnPage: 0
                    }
                };
            },
            providesTags: ['Duty'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch my duties');
                }
            }
        }),

        getDutyById: builder.query({
            query: (id) => ({
                url: `/api/duty/${id}`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['Duty'],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch duty');
                }
            }
        })

    }),
});

// Export hooks for usage in components
export const {
    useGetDutiesQuery,
    useGetDutyByIdQuery,
    useGetMyDutiesQuery
} = dutyApi;
