import { useNavigate } from "react-router-dom";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody, Button } from "reactstrap";
import {
  useGetMessageTemplatesQuery,
  useDeleteMessageTemplateMutation,
  useSyncMessageTemplateMutation,
} from "@src/redux/messageTemplates";
import messageTemplatesColumns from "./Columns";
import { useState } from "react";
import { File } from "react-feather";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import PermissionButton from "@src/components/shared/PermissionButton";

const MessageTemplats = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    country: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "messageType.code": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const [deleteMessageTemplate] = useDeleteMessageTemplateMutation();
  const [syncMessageTemplate] = useSyncMessageTemplateMutation();
  const { data, isLoading } = useGetMessageTemplatesQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const handleDeleteClick = async (item) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this message template?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteMessageTemplate(item.id)
          .unwrap()
          .then(() => {
            SuccessToast("Message template deleted Successfully!");
          })
          .catch((rejected) => {});
      },
    });
  };

  const actionTemplate = () => {
    return (
      <PermissionButton
        color="secondary"
        className="me-1"
        onClick={handleSyncTemplates}
      >
        <File size={18} />
        &nbsp; Sync Files
      </PermissionButton>
    );
  };

  const handleSyncTemplates = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to sync templates with files?",
      text: "You can delete the teamplates with no files",
      showDenyButton: true,
      icon: "question",
      confirmButtonText: "Sync only",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      denyButtonText: `Sync and delete`,
      denyButtonClasses: "btn btn-danger  ms-1",
      onConfirm: async () => {
        SyncFunc(false);
      },
      onDeny: async () => {
        SyncFunc(true);
      },
    });
  };

  const SyncFunc = async (deleteFiles) => {
    await syncMessageTemplate({
      deleteTemplateWithNoFile: deleteFiles,
    })
      .unwrap()
      .then(() => {
        SuccessToast("Message templates synced Successfully!");
      })
      .catch((rejected) => {});
  };

  return (
    <>
      <BreadCrumbs
        pageName="messageTemplateList"
        pageTitle="Message Template List"
      />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={messageTemplatesColumns(handleDeleteClick)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Message Template Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={() => {
              navigate(`/sita-messages/messageTemplates/add`);
            }}
            actionTemplate={actionTemplate}
            tableState={filters}
            addPermission="sim.message-template.add"
          />
        </CardBody>
      </Card>
    </>
  );
};

export default MessageTemplats;
