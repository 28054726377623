import React, { useState } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Alert,
} from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextInput, SelectInput, CalendarInput } from "@src/components/inputs";
import {
  faCalendar,
  faPlane,
  faTag,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { useAddLzBaggageItemMutation } from "@src/redux/lz-baggage/list";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import LoadingButton from "@src/components/shared/LoadingButton";
import { X, Edit, Plus, AlertCircle } from "react-feather";
import FileUploadModal from "./FileUploadModal";
import Loader from "@src/components/shared/Loader";
import { useGetStationListQuery } from "@src/redux/station/station";
import { format } from "date-fns";
import CameraCapture from "@src/components/shared/CameraCapture";

const moduleOptions = [
  { label: "AHL", value: "ahl" },
  { label: "OHD", value: "ohd" },
];

const lzBaggageSchema = yup.object().shape({
  module: yup.string().required("Module is required"),
  moduleNumber: yup.string().required("Module number is required"),
  moduleCreatedDate: yup.date().required("Module created date is required"),
  originalFlightNumber: yup
    .string()
    .required("Original flight number is required"),
  originalFlightTransit: yup.string().nullable(),
  destination: yup.string().required("Destination is required"),
  receivedFlightNumber: yup
    .string()
    .required("Received flight number is required"),
  receivedFlightDate: yup.date().required("Received flight date is required"),
  tagNumber: yup.string().nullable(),
  bagTypeColor: yup.string().required("Bag type/color is required"),
  rushTag: yup.string().required("Rush tag is required"),
  weight: yup
    .number()
    .positive("Weight must be positive")
    .required("Weight is required"),
  receivedDate: yup.date().required("Received date is required"),
  comments: yup.string().nullable(),
});

const AddLzBaggage = () => {
  const navigate = useNavigate();
  const [addLzBaggageItem, { isLoading }] = useAddLzBaggageItemMutation();
  const { data: stationList } = useGetStationListQuery();
  const [filesWithComments, setFilesWithComments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [capturedImages, setCapturedImages] = useState([]);

  const formMethods = useForm({
    resolver: yupResolver(lzBaggageSchema),
    mode: "onSubmit",
    defaultValues: {
      module: "",
      moduleNumber: "",
      moduleCreatedDate: "",
      originalFlightNumber: "",
      originalFlightTransit: "",
      destination: "",
      receivedFlightNumber: "",
      receivedFlightDate: "",
      tagNumber: "",
      bagTypeColor: "",
      rushTag: "",
      weight: "",
      receivedDate: "",
      comments: "",
    },
  });

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleAddFile = (fileData) => {
    const { file, comment } = fileData;
    if (fileData.isEditing) {
      const updatedFiles = filesWithComments.map((item, idx) =>
        idx === fileData.index ? { file, comment } : item
      );
      setFilesWithComments(updatedFiles);
    } else {
      setFilesWithComments([...filesWithComments, { file, comment }]);
    }

    setFileData(null);
    toggleModal();
  };

  const handleEditFile = (index) => {
    const selectedFile = filesWithComments[index];
    setFileData({ ...selectedFile, isEditing: true, index });
    toggleModal();
  };

  const handleNewFile = () => {
    setFileData(null);
    toggleModal();
  };

  const handleRemoveFile = (index) => {
    setFilesWithComments(filesWithComments.filter((_, idx) => idx !== index));
  };

  const handleImageCapture = (imageFile) => {
    setCapturedImages([
      ...capturedImages,
      {
        file: imageFile,
        comment: "captured baggage image",
      },
    ]);
  };

  const handleRemoveImage = (index) => {
    setCapturedImages(capturedImages.filter((_, idx) => idx !== index));
  };

  const handleFormSubmit = async (formData) => {
    try {
      await lzBaggageSchema.validate(formData, { abortEarly: false });

      const lzBaggageFormData = new FormData();

      // Add regular files and their props
      filesWithComments.forEach((file) => {
        lzBaggageFormData.append("files", file.file);
        const fileProps = {
          FileComment: file.comment,
          IsBaggageImage: false,
        };
        lzBaggageFormData.append("filesProps", JSON.stringify(fileProps));
      });

      // Add captured images and their props
      capturedImages.forEach((image) => {
        lzBaggageFormData.append("files", image.file);
        const imageProps = {
          FileComment: image.comment,
          IsBaggageImage: true,
        };
        lzBaggageFormData.append("filesProps", JSON.stringify(imageProps));
      });

      Object.entries({ ...formData }).forEach(([key, value]) => {
        const formattedValue = key.endsWith("Date")
          ? format(new Date(value), "yyyy-MM-dd")
          : value;
        lzBaggageFormData.append(key, formattedValue);
      });

      await addLzBaggageItem(lzBaggageFormData).unwrap();
      SuccessToast("Lz-Baggage Item Added Successfully!");
      navigate(`/baggage-management/lz-baggage/list`);
    } catch (err) {
      if (err.name === "ValidationError") {
        console.error("Validation Error:", err.errors);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <BreadCrumbs pageName="addLzBaggage" pageTitle="Add LZ Baggage" />
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <Row>
            <Col lg="12" md="12">
              <Card>
                <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                  <CardTitle tag="h1" className="fw-bolder">
                    Add LZ Baggage
                  </CardTitle>
                  <div>
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      color="primary"
                    >
                      Save
                    </LoadingButton>
                    <Button
                      type="button"
                      color="warning"
                      className="mx-1"
                      onClick={() => {
                        navigate(`/baggage-management/lz-baggage/list`);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="info-container">
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="mb-2">
                          <SelectInput
                            label="Module"
                            name="module"
                            options={moduleOptions}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput
                            label="Module Number"
                            name="moduleNumber"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <CalendarInput
                            label="Module Created Date"
                            name="moduleCreatedDate"
                            icon={faCalendar}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput
                            label="Original Flight Number"
                            name="originalFlightNumber"
                            icon={faPlane}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput
                            label="Original Flight Transit"
                            name="originalFlightTransit"
                            icon={faPlane}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <SelectInput
                            label="Destination"
                            name="destination"
                            options={
                              stationList?.map((type) => ({
                                value: type.id,
                                label: type.id,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput
                            label="Received Flight Number"
                            name="receivedFlightNumber"
                            icon={faPlane}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <CalendarInput
                            label="Received Flight Date"
                            name="receivedFlightDate"
                            icon={faCalendar}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput
                            label="Tag Number"
                            name="tagNumber"
                            icon={faTag}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput
                            label="Bag Type/Color"
                            name="bagTypeColor"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput label="Rush Tag" name="rushTag" />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <TextInput
                            label="Weight"
                            name="weight"
                            icon={faWeight}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <CalendarInput
                            label="Received Date"
                            name="receivedDate"
                            icon={faCalendar}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-2">
                          <TextInput
                            label="Comments"
                            name="comments"
                            type="textarea"
                            rows="3"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="12" md="12">
              <Card>
                <CardHeader className="border-bottom flex justify-between items-center">
                  <CardTitle tag="h5">Upload Files</CardTitle>
                  <div className="flex items-center gap-2">
                    <CameraCapture
                      setImageFile={handleImageCapture}
                      className="me-1"
                    />
                    <Button outline color="warning" onClick={handleNewFile}>
                      <Plus size={14} />
                      Add File
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  {filesWithComments.length > 0 || capturedImages.length > 0 ? (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>File Name</th>
                          <th>Comment</th>
                          <th>Type</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {capturedImages.map((image, index) => (
                          <tr key={`image-${index}`}>
                            <td>{index + 1}</td>
                            <td>{image.file.name}</td>
                            <td>{image.comment}</td>
                            <td>Captured Image</td>
                            <td>
                              <Button
                                outline
                                color="danger"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <X size={14} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {filesWithComments.map((file, index) => (
                          <tr key={`file-${index}`}>
                            <td>{capturedImages.length + index + 1}</td>
                            <td>{file.file.name}</td>
                            <td>{file.comment}</td>
                            <td>Regular File</td>
                            <td>
                              <Button
                                outline
                                color="primary"
                                onClick={() => handleEditFile(index)}
                                className="me-1"
                              >
                                <Edit size={14} />
                              </Button>
                              <Button
                                outline
                                color="danger"
                                onClick={() => handleRemoveFile(index)}
                              >
                                <X size={14} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Alert color="dark">
                      <div className="alert-body mt-1">
                        <AlertCircle size={14} />
                        <span className="align-middle ms-50">
                          No files added yet.
                        </span>
                      </div>
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
        <FileUploadModal
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          onSave={handleAddFile}
          fileData={fileData}
        />
      </FormProvider>
    </>
  );
};

export default AddLzBaggage;
