import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Table,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useGetLzBaggageQuery,
  useUpdateLzBaggageItemMutation,
} from "@src/redux/lz-baggage/list";
import Loader from "@src/components/shared/Loader";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import CalendarInput from "@src/components/inputs/CalendarInput";
import { TextInput, SelectInput } from "@src/components/inputs";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { format } from "date-fns";
import FileUploadModal from "./FileUploadModal";
import { X, Edit, Plus, AlertCircle, Download } from "react-feather";
import {
  faCalendar,
  faPlane,
  faTag,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";
import { useGetStationListQuery } from "@src/redux/station/station";
import { useLazyDownloadFileQuery } from "@src/redux/general/file";
import CameraCapture from "@src/components/shared/CameraCapture";

const moduleOptions = [
  { label: "AHL", value: "AHL" },
  { label: "OHD", value: "OHD" },
];

const lzBaggageSchema = yup.object().shape({
  module: yup.string().required("Module is required"),
  moduleNumber: yup.string().required("Module number is required"),
  moduleCreatedDate: yup.date().required("Module created date is required"),
  originalFlightNumber: yup
    .string()
    .required("Original flight number is required"),
  destination: yup.string().required("Destination is required"),
  receivedFlightNumber: yup
    .string()
    .required("Received flight number is required"),
  receivedFlightDate: yup.date().required("Received flight date is required"),
  tagNumber: yup.string().nullable(),
  bagTypeColor: yup.string().required("Bag type/color is required"),
  rushTag: yup.string().required("Rush tag is required"),
  weight: yup
    .number()
    .positive("Weight must be positive")
    .required("Weight is required"),
  receivedDate: yup.date().required("Received date is required"),
  comments: yup.string().nullable(),
});

const UpdateLzBaggage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateLzBaggage, { isLoading: isUpdating }] =
    useUpdateLzBaggageItemMutation();
  const { data: baggageData, isLoading: isFetching } = useGetLzBaggageQuery(id);
  const [filesWithComments, setFilesWithComments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: stationList } = useGetStationListQuery();
  const [fileData, setFileData] = useState(null);
  const [downloadFile] = useLazyDownloadFileQuery();
  const [capturedImages, setCapturedImages] = useState([]);

  const formMethods = useForm({
    resolver: yupResolver(lzBaggageSchema),
    defaultValues: {
      module: "",
      moduleNumber: "",
      moduleCreatedDate: "",
      originalFlightNumber: "",
      destination: "",
      receivedFlightNumber: "",
      receivedFlightDate: "",
      tagNumber: "",
      bagTypeColor: "",
      rushTag: "",
      weight: "",
      receivedDate: "",
      comments: "",
    },
  });

  useEffect(() => {
    if (baggageData) {
      const { files, ...rest } = baggageData;
      formMethods.reset({
        ...rest,
        moduleCreatedDate: baggageData.moduleCreatedDate || "",
        receivedFlightDate: baggageData.receivedFlightDate || "",
        receivedDate: baggageData.receivedDate || "",
        module: baggageData.module || "",
      });
      setFilesWithComments(
        files.map((file) => ({
          file: {
            name: file.name,
            type: file.type,
            size: file.size,
            id: file.id,
            identifier: file.identifier,
          },
          comment: file.comments || "",
        }))
      );
    }
  }, [baggageData, formMethods]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleAddFile = (fileData) => {
    const { file, comment } = fileData;
    if (fileData.isEditing) {
      const updatedFiles = filesWithComments.map((item, idx) =>
        idx === fileData.index ? { file, comment } : item
      );
      setFilesWithComments(updatedFiles);
    } else {
      setFilesWithComments([...filesWithComments, { file, comment }]);
    }

    setFileData(null);
    toggleModal();
  };

  const handleEditFile = (index) => {
    const selectedFile = filesWithComments[index];
    setFileData({ ...selectedFile, isEditing: true, index });
    toggleModal();
  };

  const handleNewFile = () => {
    setFileData(null);
    toggleModal();
  };

  const handleRemoveFile = (index) => {
    setFilesWithComments(filesWithComments.filter((_, idx) => idx !== index));
  };

  const handleImageCapture = (imageFile) => {
    setCapturedImages([
      ...capturedImages,
      {
        file: imageFile,
        comment: "captured baggage image",
      },
    ]);
  };

  const handleRemoveImage = (index) => {
    setCapturedImages(capturedImages.filter((_, idx) => idx !== index));
  };

  const handleFormSubmit = async (formData) => {
    try {
      await lzBaggageSchema.validate(formData, { abortEarly: false });

      const lzBaggageFormData = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        const formattedValue = key.endsWith("Date")
          ? format(new Date(value), "yyyy-MM-dd")
          : value;
        lzBaggageFormData.append(key, formattedValue);
      });

      // Process filesWithComments
      const newFiles = [];
      const newFilesProps = [];
      const oldFilesProps = [];

      filesWithComments.forEach((item) => {
        debugger;
        if (item.file.id) {
          // Old files with ID
          oldFilesProps.push({
            id: item.file.id,
            FileComment: item.comment,
            IsBaggageImage: false,
          });
        } else {
          // New files without ID
          newFiles.push(item.file);
          newFilesProps.push({
            Comments: item.comment,
            IsBaggageImage: false,
          });
        }
      });

      // Add captured images
      capturedImages.forEach((image) => {
        newFiles.push(image.file);
        newFilesProps.push({
          Comments: image.comment,
          IsBaggageImage: true,
        });
      });

      // Append new files
      newFiles.forEach((file) => {
        lzBaggageFormData.append("newFiles", file);
      });

      lzBaggageFormData.append("newFilesProps", JSON.stringify(newFilesProps));
      lzBaggageFormData.append("oldFilesProps", JSON.stringify(oldFilesProps));

      // Submit the form data
      await updateLzBaggage({ id, formData: lzBaggageFormData }).unwrap();
      SuccessToast("LZ Baggage Updated Successfully!");
      //navigate("/baggage-management/lz-baggage/list");
    } catch (error) {
      console.error("Error updating LZ baggage:", error);
    }
  };

  const handleDownload = async (identifier) => {
    const category = "LzBaggage";
    try {
      const response = await downloadFile(
        `identifier=${identifier}&category=${category}`
      ).unwrap();
      const blob = new Blob([response], { type: response.type });
      saveAs(blob, identifier);
      SuccessToast(`File downloaded successfully!`);
    } catch (err) {
      console.error("Error downloading file:", err);
    }
  };

  if (isFetching) return <Loader />;

  return (
    <Fragment>
      <BreadCrumbs pageName="updateLzBaggage" pageTitle="Update LZ Baggage" />
      <Card>
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
            <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
              <CardTitle tag="h1" className="fw-bolder">
                Add LZ Baggage
              </CardTitle>
              <div>
                <Button color="primary" type="submit" disabled={isUpdating}>
                  Save
                </Button>
                <Button
                  type="button"
                  color="warning"
                  className="mx-1"
                  onClick={() => {
                    navigate(`/baggage-management/lz-baggage/list`);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <div className="info-container">
                <br />
                <Row>
                  <Col md={6}>
                    <div className="mb-2">
                      <SelectInput
                        label="Module"
                        name="module"
                        options={moduleOptions}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput label="Module Number" name="moduleNumber" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <CalendarInput
                        label="Module Created Date"
                        name="moduleCreatedDate"
                        icon={faCalendar}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput
                        label="Original Flight Number"
                        name="originalFlightNumber"
                        icon={faPlane}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <SelectInput
                        label="Destination"
                        name="destination"
                        options={
                          stationList?.map((type) => ({
                            value: type.id,
                            label: type.id,
                          })) || []
                        }
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput
                        label="Received Flight Number"
                        name="receivedFlightNumber"
                        icon={faPlane}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <CalendarInput
                        label="Received Flight Date"
                        name="receivedFlightDate"
                        icon={faCalendar}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput
                        label="Tag Number"
                        name="tagNumber"
                        icon={faTag}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput label="Bag Type/Color" name="bagTypeColor" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput label="Rush Tag" name="rushTag" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput label="Weight" name="weight" icon={faWeight} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <CalendarInput
                        label="Received Date"
                        name="receivedDate"
                        icon={faCalendar}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-2">
                      <TextInput
                        label="Comments"
                        name="comments"
                        type="textarea"
                        rows="3"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <Col lg="12" md="12">
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle tag="h5">Upload Files</CardTitle>
                  <div className="flex items-center gap-2">
                    <CameraCapture
                      setImageFile={handleImageCapture}
                      className="me-1"
                    />
                    <Button outline color="warning" onClick={handleNewFile}>
                      <Plus size={14} />
                      Add File
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  {filesWithComments.length > 0 || capturedImages.length > 0 ? (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>File Name</th>
                          <th>Comment</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {capturedImages.map((image, index) => (
                          <tr key={`image-${index}`}>
                            <td>{index + 1}</td>
                            <td>{image.file.name}</td>
                            <td>{image.comment}</td>
                            <td>
                              <Button
                                outline
                                color="danger"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <X size={14} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {filesWithComments.map((file, index) => (
                          <tr key={`file-${index}`}>
                            <td>{capturedImages.length + index + 1}</td>
                            <td>{file.file.name}</td>
                            <td>{file.comment}</td>
                            <td>
                              <Button
                                outline
                                color="primary"
                                onClick={() => handleEditFile(index)}
                                className="me-1"
                              >
                                <Edit size={14} />
                              </Button>
                              <Button
                                outline
                                color="danger"
                                className="me-1"
                                onClick={() => handleRemoveFile(index)}
                              >
                                <X size={14} />
                              </Button>
                              {file.file.id && (
                                <Button
                                  outline
                                  color="info"
                                  onClick={() =>
                                    handleDownload(file.file.identifier)
                                  }
                                >
                                  <Download size={14} />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Alert color="dark">
                      <div className="alert-body mt-1">
                        <AlertCircle size={14} />
                        <span className="align-middle ms-50">
                          No files added yet.
                        </span>
                      </div>
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
            {/* Display all errors */}
            {Object.keys(formMethods.formState.errors).length > 0 && (
              <div className="mt-3">
                <Alert color="danger">
                  <ul>
                    {Object.values(formMethods.formState.errors).map(
                      (error, index) => (
                        <li key={index}>{error.message}</li>
                      )
                    )}
                  </ul>
                </Alert>
              </div>
            )}
          </Form>
        </FormProvider>
      </Card>
      <FileUploadModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        onSave={handleAddFile}
        fileData={fileData}
      />
    </Fragment>
  );
};

export default UpdateLzBaggage;
