import React, { useState } from "react";
import { Card, CardHeader, Button, ButtonGroup } from "reactstrap";
import { faXmark, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RejectModal from "./RejectModal";
import { useAcceptCompensationItemMutation } from "@src/redux/compensation/list";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const ActionToolbar = ({ item }) => {
  const [rejectModelVisibility, setRejectModelVisibility] = useState(false);
  const [acceptCompensation] = useAcceptCompensationItemMutation();

  const handleAcceptClick = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Accept this Compensation?",
      icon: "question",
      confirmButtonText: "Accept",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await acceptCompensation({ id: item.id })
          .unwrap()
          .then((fulfilled) => {
            SuccessToast("Compensation accepted Successfully!");
            navigate(`/baggage-management/compensation/list`);
          })
          .catch((rejected) => {});
      },
    });
  };

  if (item.caseStatus != "pending") return <></>;

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom p-1 flex flex-wrap gap-2"
          style={{ justifyContent: "right" }}
        >
          <ButtonGroup>
            <>
              <Button
                outline
                type="button"
                color="secondary"
                href={`/baggage-management/compensation/list/${item.id}/update`}
                tag="a"
              >
                <FontAwesomeIcon icon={faEdit} className="font-medium-3" />{" "}
                &nbsp; Update
              </Button>
              <Button
                outline
                type="button"
                color="secondary"
                onClick={() => setRejectModelVisibility(true)}
              >
                <FontAwesomeIcon icon={faXmark} className="font-medium-3" />{" "}
                &nbsp; Reject
              </Button>
              <Button
                outline
                type="button"
                color="secondary"
                onClick={() => handleAcceptClick()}
              >
                <FontAwesomeIcon icon={faXmark} className="font-medium-1" />
                &nbsp; Accept
              </Button>
            </>
          </ButtonGroup>
        </CardHeader>
      </Card>
      <RejectModal
        visible={rejectModelVisibility}
        toggle={() => setRejectModelVisibility(false)}
        data={item}
      />
    </>
  );
};

export default ActionToolbar;
