import React, { useState, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
} from "reactstrap";
import { FileText, Trash, DownloadCloud } from "react-feather";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { useGetFileUploadSettingListQuery } from "@src/redux/general/file";

const UploadFile = ({ files, setFiles, formMethods, maxFiles = null }) => {
  const { data: fileUploadSettings } = useGetFileUploadSettingListQuery();

  const mapMimeToExtensions = (settings) => {
    if (!settings) return {};

    const { allowedContentTypes, allowedExtensions } = settings;

    const mimeToExtensions = allowedContentTypes.reduce(
      (acc, mimeType, index) => {
        if (!acc[mimeType]) {
          acc[mimeType] = [];
        }
        acc[mimeType].push(allowedExtensions[index]);
        return acc;
      },
      {}
    );

    return mimeToExtensions;
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: maxFiles !== 1,
    accept: mapMimeToExtensions(fileUploadSettings),
    maxSize: fileUploadSettings?.maxFileSize || 0,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        const unsupportedFiles = rejectedFiles
          .map(
            (file) => `${file.file.name} (.${file.file.name.split(".").pop()})`
          )
          .join(", ");

        openSweetAlert({
          title: "Unsupported file format or file size exceeded",
          text: `The following files are not supported or exceed the size limit: ${unsupportedFiles}`,
          icon: "error",
          confirmButtonClasses: "btn btn-danger",
          confirmButtonText: "OK",
        });
      } else {
        const newFiles = [...files, ...acceptedFiles].slice(
          0,
          maxFiles || undefined
        );
        setFiles(newFiles);
        formMethods.setValue("files", newFiles);
      }
    },
  });

  const handleRemoveFile = (file) => {
    const filteredFiles = files.filter((f) => f.name !== file.name);
    setFiles(filteredFiles);
    formMethods.setValue("files", filteredFiles);
  };

  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          className="rounded"
          alt={file.name}
          src={URL.createObjectURL(file)}
          height="28"
          width="28"
        />
      );
    } else {
      return <FileText size="28" />;
    }
  };

  const fileList = files.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p
            className="file-name mb-0"
            style={{ wordBreak: "break-word", maxWidth: "400px" }}
          >
            {file.name}
          </p>
          <p className="file-size mb-0">{(file.size / 1024).toFixed(2)} KB</p>
        </div>
      </div>
      <Button
        id={`delete-${index}`}
        color="secondary"
        outline
        size="sm"
        className="btn-icon"
        onClick={() => handleRemoveFile(file)}
      >
        <Trash size={14} /> Delete
      </Button>
      <UncontrolledTooltip target={`delete-${index}`} placement="top">
        Delete File
      </UncontrolledTooltip>
    </ListGroupItem>
  ));

  return (
    <div className="mb-2">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="d-flex align-items-center justify-content-center flex-column">
          <DownloadCloud size={64} className="text-primary mb-1" />
          <p className="text-center">
            <span className="text-muted"> Max file size: </span>
            <span className="text-muted">
              {(fileUploadSettings?.maxFileSize / 1024 / 1024).toFixed(2)} MB
            </span>
            <br />
            <span className="text-muted">Accepted file types: </span>
            <span className="font-small-2 text-muted">
              {fileUploadSettings?.allowedExtensions.join(", ")}
            </span>
          </p>
          <h5 className="text-warning">
            Drop files here or click{" "}
            <a href="/" onClick={(e) => e.preventDefault()}>
              browse
            </a>{" "}
            through your machine
          </h5>
        </div>
      </div>
      {files.length ? (
        <Fragment>
          <ListGroup className="my-2">{fileList}</ListGroup>
        </Fragment>
      ) : null}
    </div>
  );
};

export default UploadFile;
