import React, { useState } from "react";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.i18n";
import { InputGroup, InputGroupText, Label, Input, FormFeedback } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext, Controller } from "react-hook-form";

const countries = [
  // Middle East
  { value: "SA", label: "Saudi Arabia", code: "+966" },
  { value: "AE", label: "United Arab Emirates", code: "+971" },
  { value: "QA", label: "Qatar", code: "+974" },
  { value: "BH", label: "Bahrain", code: "+973" },
  { value: "KW", label: "Kuwait", code: "+965" },
  { value: "OM", label: "Oman", code: "+968" },
  { value: "YE", label: "Yemen", code: "+967" },
  { value: "IQ", label: "Iraq", code: "+964" },
  { value: "JO", label: "Jordan", code: "+962" },
  { value: "LB", label: "Lebanon", code: "+961" },
  { value: "PS", label: "Palestine", code: "+970" },
  { value: "SY", label: "Syria", code: "+963" },

  // Africa
  { value: "EG", label: "Egypt", code: "+20" },
  { value: "DZ", label: "Algeria", code: "+213" },
  { value: "TN", label: "Tunisia", code: "+216" },
  { value: "MA", label: "Morocco", code: "+212" },
  { value: "LY", label: "Libya", code: "+218" },
  { value: "SD", label: "Sudan", code: "+249" },
  { value: "SO", label: "Somalia", code: "+252" },
  { value: "DJ", label: "Djibouti", code: "+253" },
  { value: "KE", label: "Kenya", code: "+254" },
  { value: "ET", label: "Ethiopia", code: "+251" },
  { value: "ZA", label: "South Africa", code: "+27" },
  { value: "NG", label: "Nigeria", code: "+234" },

  // Europe
  { value: "GB", label: "United Kingdom", code: "+44" },
  { value: "FR", label: "France", code: "+33" },
  { value: "DE", label: "Germany", code: "+49" },
  { value: "IT", label: "Italy", code: "+39" },
  { value: "ES", label: "Spain", code: "+34" },
  { value: "PT", label: "Portugal", code: "+351" },
  { value: "NL", label: "Netherlands", code: "+31" },
  { value: "BE", label: "Belgium", code: "+32" },
  { value: "CH", label: "Switzerland", code: "+41" },
  { value: "SE", label: "Sweden", code: "+46" },
  { value: "NO", label: "Norway", code: "+47" },
  { value: "DK", label: "Denmark", code: "+45" },
  { value: "FI", label: "Finland", code: "+358" },
  { value: "IE", label: "Ireland", code: "+353" },
  { value: "PL", label: "Poland", code: "+48" },
  { value: "AT", label: "Austria", code: "+43" },
  { value: "GR", label: "Greece", code: "+30" },
  { value: "RU", label: "Russia", code: "+7" },

  // Asia
  { value: "CN", label: "China", code: "+86" },
  { value: "JP", label: "Japan", code: "+81" },
  { value: "KR", label: "South Korea", code: "+82" },
  { value: "IN", label: "India", code: "+91" },
  { value: "PK", label: "Pakistan", code: "+92" },
  { value: "BD", label: "Bangladesh", code: "+880" },
  { value: "ID", label: "Indonesia", code: "+62" },
  { value: "MY", label: "Malaysia", code: "+60" },
  { value: "SG", label: "Singapore", code: "+65" },
  { value: "TH", label: "Thailand", code: "+66" },
  { value: "VN", label: "Vietnam", code: "+84" },
  { value: "PH", label: "Philippines", code: "+63" },
  { value: "MM", label: "Myanmar", code: "+95" },
  { value: "LK", label: "Sri Lanka", code: "+94" },
  { value: "NP", label: "Nepal", code: "+977" },
  { value: "AF", label: "Afghanistan", code: "+93" },
  { value: "IR", label: "Iran", code: "+98" },
  { value: "TR", label: "Turkey", code: "+90" },

  // Americas
  { value: "US", label: "United States", code: "+1" },
  { value: "CA", label: "Canada", code: "+1" },
  { value: "MX", label: "Mexico", code: "+52" },
  { value: "BR", label: "Brazil", code: "+55" },
  { value: "AR", label: "Argentina", code: "+54" },
  { value: "CL", label: "Chile", code: "+56" },
  { value: "CO", label: "Colombia", code: "+57" },
  { value: "PE", label: "Peru", code: "+51" },
  { value: "VE", label: "Venezuela", code: "+58" },

  // Oceania
  { value: "AU", label: "Australia", code: "+61" },
  { value: "NZ", label: "New Zealand", code: "+64" },
].sort((a, b) => a.label.localeCompare(b.label));

const PhoneInput = ({
  label,
  name,
  icon,
  twoLines,
  color = "primary",
  placeholder,
  ...props
}) => {
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((c) => c.value === "SA") || countries[0]
  );

  const {
    control,
    formState: { errors }
  } = useFormContext();

  // Get nested error message if it exists
  const errorMessage = name?.split('.').reduce((acc, curr) => acc?.[curr], errors)?.message;

  const handleCountryChange = (e, onChange) => {
    const country = countries.find((c) => c.value === e.target.value);
    setSelectedCountry(country);
    if (onChange) {
      const localNumber = e.target.rawValue ? e.target.rawValue.replace(/[^0-9]/g, "") : "";
      onChange(`${country.code}${localNumber}`);
    }
  };

  const handlePhoneChange = (e, onChange) => {
    if (onChange) {
      const rawValue = e.target.rawValue ? e.target.rawValue.replace(/[^0-9]/g, "") : "";
      onChange(`${selectedCountry.code}${rawValue}`);
    }
  };

  const getLocalNumber = (value) => {
    if (!value) return "";
    return value.replace(new RegExp(`^\\${selectedCountry.code}`), "");
  };

  const options = {
    phone: true,
    phoneRegionCode: selectedCountry.value,
    numericOnly: true,
    blocks: [3, 3, 4],
    delimiters: ["-", "-"],
  };

  return (
    <div className={`form-group ${twoLines ? "mb-2" : ""}`}>
      {label && <Label>{label}</Label>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <InputGroup className="input-group-merge">
              {icon && (
                <span className={`p-inputgroup-addon text-${color}`}>
                  <FontAwesomeIcon icon={icon} />
                </span>
              )}
              <Input
                type="select"
                value={selectedCountry.value}
                onChange={(e) => handleCountryChange(e, onChange)}
                style={{ maxWidth: "200px" }}
                invalid={!!error}
              >
                {countries.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label} ({country.code})
                  </option>
                ))}
              </Input>
              <Cleave
                className={`form-control ${error ? "is-invalid" : ""}`}
                placeholder={placeholder || "Enter phone number"}
                options={options}
                onChange={(e) => handlePhoneChange(e, onChange)}
                value={getLocalNumber(value)}
                invalid={!!error}
                {...props}
              />
            </InputGroup>
            {error && <FormFeedback className="d-block">{error.message}</FormFeedback>}
          </>
        )}
      />
    </div>
  );
};

export default PhoneInput;
