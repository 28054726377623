import { formatDateOnly } from "@src/utility/Utils";
import { Calendar } from "primereact/calendar";
import { format } from "date-fns";
import {
  faEye,
  faSuitcaseRolling,
  faPersonWalkingLuggage,
  faEdit,
  faTruck,
  faForward,
  faHammer,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import DeleteLzBaggage from "./delete";
import { formatPascalCaseToLabel } from "@src/utility/Utils";
import { MoreVertical } from "react-feather";
import RevertLzBaggage from "./revert";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";

const orderStatuses = [
  { label: "Pending", value: "pending" },
  { label: "Delivered to Passenger", value: "deliveredToPassenger" },
  { label: "Delivered to Charity", value: "deliveredToCharity" },
  { label: "Destroyed", value: "destroyed" },
  { label: "Forwarded", value: "forwarded" },
];

const moduleOptions = [
  { label: "AHL", value: "ahl" },
  { label: "OHD", value: "ohd" },
];

const getModuleIcon = (type) => {
  switch (type) {
    case "ahl":
      return faPersonWalkingLuggage;
    case "ohd":
      return faSuitcaseRolling;
  }
};

const getStatusSeverity = (status) => {
  switch (status) {
    case "pending":
      return "info";
    case "deliveredToPassenger":
      return "success";
    case "deliveredToCharity":
      return "primary";
    case "destroyed":
      return "danger";
    default:
      return "warning"; //Forwarded
  }
};
const getStatusLabel = (status) => {
  switch (status) {
    case "deliveredToPassenger":
      return "To Pax";
    case "deliveredToCharity":
      return "To Charity";
    default:
      return formatPascalCaseToLabel(status);
  }
};

const lzBaggageColumns = (
  handleDeliverClick,
  handleForwardClick,
  handleDestroyClick
) => {
  const dateBodyTemplate = (rowData, field) =>
    formatDateOnly(new Date(rowData[field]));

  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value ? new Date(options.value) : null}
      onChange={(e) =>
        options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index)
      }
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );
  const actionsBodyTemplate = (
    row,
    handleDeliverClick,
    handleForwardClick,
    handleDestroyClick
  ) => (
    <div className="d-flex justify-space-between">
      <a
        id="details"
        className="btn btn-icon rounded-circle btn-outline-dark"
        style={{ padding: ".515rem .536rem" }}
        outline
        color="primary"
        href={`/baggage-management/lz-baggage/list/${row.id}`}
        target="_self"
      >
        <FontAwesomeIcon icon={faEye} className="font-medium-3" />
      </a>
      <UncontrolledTooltip target="details" placement="top">
        Details
      </UncontrolledTooltip>
      <Button
        style={{ padding: ".515rem .536rem" }}
        id="logs"
        className="btn-icon rounded-circle ms-1"
        outline
        color="dark"
        href={`/baggage-management/lz-baggage/histories/${row.id}`}
      >
        <span className="text-uppercase">
          <FontAwesomeIcon icon={faFileLines} className="font-medium-3" />
        </span>
      </Button>
      <UncontrolledTooltip target="logs" placement="top">
        Show Histories
      </UncontrolledTooltip>
      {row.status == "deliveredToCharity" || row.status == "forwarded" ? (
        <RevertLzBaggage item={row} listButton={true} />
      ) : (
        ""
      )}
      {row.status == "pending" ? (
        <>
          <UncontrolledDropdown>
            <DropdownToggle className="pe-1" tag="span">
              <Button
                className="btn-icon rounded-circle ms-1"
                outline
                color="dark"
              >
                <MoreVertical size={15} />
              </Button>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem
                onClick={() => {
                  handleDeliverClick({ ...row });
                }}
              >
                <FontAwesomeIcon icon={faTruck} className="font-medium-3" />
                <span className="align-middle ms-50">Deliver</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  handleForwardClick({ ...row });
                }}
              >
                <FontAwesomeIcon icon={faForward} className="font-medium-3" />
                <span className="align-middle ms-50">Forward</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  handleDestroyClick({ ...row });
                }}
              >
                <FontAwesomeIcon icon={faHammer} className="font-medium-3" />
                <span className="align-middle ms-50">Destroy</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                href={`/baggage-management/lz-baggage/list/${row.id}/update`}
                tag="a"
              >
                <FontAwesomeIcon icon={faEdit} className="font-medium-3" />
                <span className="align-middle ms-50">Update</span>
              </DropdownItem>
              <DeleteLzBaggage item={row} listButton={true} />
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ) : (
        ""
      )}
    </div>
  );
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={getStatusLabel(rowData.status)}
        severity={getStatusSeverity(rowData.status)}
        className="text-capitalize"
      />
    );
  };

  const statusItemTemplate = ({ label, value }) => {
    return (
      <span className="text-capitalize">
        <Tag value={label} severity={getStatusSeverity(value)} />
      </span>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={orderStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const moduleBodyTemplate = (rowData) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getModuleIcon(rowData.module)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {rowData.module}
      </span>
    );
  };
  const moduleItemTemplate = ({ label, value }) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getModuleIcon(value)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {label}
      </span>
    );
  };
  const moduleFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={moduleOptions}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={moduleItemTemplate}
        placeholder="Select Module"
        className="p-column-filter"
        showClear
      />
    );
  };

  const headerColumns = (
    <ColumnGroup>
      <Row>
        <Column header="No" field="moduleNumber" filter rowSpan={3} />
        <Column
          header="Module"
          field="module"
          filter
          filterElement={moduleFilterTemplate}
          rowSpan={3}
        />
        <Column
          header="Status"
          field="status"
          filter
          filterElement={statusFilterTemplate}
          rowSpan={3}
        />
        <Column
          header="Original Flight No"
          field="originalFlightNumber"
          filter
          rowSpan={3}
        />
        <Column header="Destination" field="destination" filter rowSpan={3} />
        <Column header="Received Flight" colSpan={2} />
        <Column header="Baggage Info" colSpan={3} />
        <Column
          header="Received Date"
          field="receivedDate"
          filter
          filterElement={dateFilterTemplate}
          rowSpan={3}
        />
        <Column
          header="Created Date"
          field="moduleCreatedDate"
          filter
          filterElement={dateFilterTemplate}
          rowSpan={3}
        />
        <Column header="Actions" rowSpan={3} />
      </Row>
      <Row>
        <Column header="No" field="receivedFlightNumber" filter />
        <Column
          header="Date"
          field="receivedFlightDate"
          filter
          filterElement={dateFilterTemplate}
        />
        <Column header="Tag No" field="tagNumber" filter />
        <Column header="Type/Color" field="bagTypeColor" filter />
        <Column header="Rush Tag" field="rushTag" filter />
      </Row>
    </ColumnGroup>
  );

  return {
    bodyColumns: [
      { header: "Module No", field: "moduleNumber", filter: true },
      {
        header: "Module",
        field: "module",
        filter: true,
        body: moduleBodyTemplate,
      },
      {
        header: "Status",
        field: "status",
        body: statusBodyTemplate,
      },
      {
        header: "Original Flight No",
        field: "originalFlightNumber",
        filter: true,
      },
      { header: "Destination", field: "destination", filter: true },
      {
        header: "Received Flight No",
        field: "receivedFlightNumber",
        filter: true,
      },
      {
        header: "Received Flight Date",
        field: "receivedFlightDate",
        filter: true,
        sortable: false,
        dataType: "date",
        body: (rowData) => dateBodyTemplate(rowData, "receivedFlightDate"),
      },
      { header: "Tag No", field: "tagNumber", filter: true },
      { header: "Type/ Color", field: "bagTypeColor", filter: true },
      {
        header: "Rush Tag",
        filter: true,
        field: "rushTag",
        sortable: false,
      },
      {
        header: "Received Date",
        field: "receivedDate",
        filter: true,
        sortable: false,
        dataType: "date",
        body: (rowData) => dateBodyTemplate(rowData, "receivedDate"),
      },
      {
        header: "Created Date",
        field: "moduleCreatedDate",
        filter: true,
        sortable: false,
        dataType: "date",
        body: (rowData) => dateBodyTemplate(rowData, "moduleCreatedDate"),
      },
      {
        header: "Actions",
        body: (rowData) =>
          actionsBodyTemplate(
            rowData,
            handleDeliverClick,
            handleForwardClick,
            handleDestroyClick
          ),
        headerStyle: { textAlign: "center" },
      },
    ],
    headerColumns: headerColumns,
  };
};

export default lzBaggageColumns;
