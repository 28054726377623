// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import { combineReducers } from 'redux';
import { mealApi } from './meals';
import { mealCycleApi } from './meal-cycle';
import { dutyApi } from './duties';
import { reportsApi } from './reports';
import { crewApi } from './crew';
import { seatConfigurationApi } from './seatConfiguration';

const rootReducer = combineReducers({
  navbar,
  layout,
  "mealApi": mealApi.reducer,
  "mealCycleApi": mealCycleApi.reducer,
  "dutyApi": dutyApi.reducer,
  "reportsApi": reportsApi.reducer,
  "crewApi": crewApi.reducer,
  "seatConfigurationApi": seatConfigurationApi.reducer
});

export default rootReducer;
