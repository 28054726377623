import { mainApi } from "../mainApi";

export const currencyApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyList: builder.query({
      query: () => ({
        url: `/currency/list`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrencyListQuery } = currencyApi;
