import CompensationList from ".";
import CompensationDetails from "./details";
import AddCompensation from "./add";
import UpdateCompensation from "./update";

const CompensationRoutes = [
  {
    path: "/compensation/list",
    index: true,
    element: <CompensationList />,
  },
  {
    path: "/compensation/list/:id",
    element: <CompensationDetails />,
  },
  {
    path: "/compensation/list/add",
    element: <AddCompensation />,
  },
  {
    path: "/compensation/list/:id/update",
    element: <UpdateCompensation />,
  },
];

export default CompensationRoutes;
