import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  InputGroup,
} from "reactstrap";
import { useGetcompensationQuery } from "@src/redux/compensation/list";
import FileDetailView from "@src/components/shared/FileDetailView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingDollar,
  faPlane,
  faUser,
  faHistory,
  faFileLines,
  faMoneyBill,
  faHome,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { formatDateOnly, formatDateTime } from "@src/utility/Utils";
import { Tag } from "primereact/tag";
import Timeline from "@components/timeline";
import {
  getStatusSeverity,
  getCaseTypeIcon,
  getSubTypeIcon,
  getFlightTypeIcon,
} from "./constants";
import ActionToolbar from "./ActionToolbar";

const CompensationDetails = () => {
  const { id } = useParams();
  const { data, error } = useGetcompensationQuery(id);
  const [active, setActive] = useState("1");

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  return (
    <>
      <BreadCrumbs
        pageName="compensationDetails"
        pageTitle="Compensation Details"
      />
      {data ? (
        <Row>
          <Col lg="4" md="4" xs="12">
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h1" className="fw-bolder">
                  <strong>
                    <span className="text-primary">
                      <FontAwesomeIcon icon={faHandHoldingDollar} />
                    </span>{" "}
                    <span className="fw-bolder mb-25">
                      Compensation Details
                    </span>
                  </strong>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="info-container">
                  <br />
                  <Row>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Case Code:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.caseCode}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Case Type:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <span className="text-primary">
                        <FontAwesomeIcon
                          icon={getCaseTypeIcon(data.caseType)}
                        />
                      </span>{" "}
                      {data.caseType}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Sub Type:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={getSubTypeIcon(data.subType)} />
                      </span>{" "}
                      {data.subType}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Status:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <InputGroup>
                        <Tag
                          value={data.caseStatus}
                          severity={getStatusSeverity(data.caseStatus)}
                          className="text-capitalize"
                        />
                      </InputGroup>
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Amount:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.amount || "-"}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Station:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.station}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Fault Station:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.faultStation}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Close Date:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.closeDate &&
                        formatDateTime(new Date(data.closeDate))}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Created By:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.createdBy || "-"}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Created Date:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {formatDateTime(new Date(data.createdOnUtc))}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Modified By:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.modifiedBy || "-"}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Modified Date:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.modifiedOnUtc &&
                        formatDateTime(new Date(data.modifiedOnUtc))}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="8" md="8" xs="12">
            <ActionToolbar item={data} />
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={active === "1"}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <FontAwesomeIcon icon={faFileLines} className="me-1" />
                  Other Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={active === "2"}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <FontAwesomeIcon icon={faUser} className="me-1" />
                  Pax Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={active === "3"}
                  onClick={() =>
                    data.compensationDeductionDetail?.isDeduction && toggle("3")
                  }
                  disabled={!data.compensationDeductionDetail?.isDeduction}
                >
                  <FontAwesomeIcon icon={faMoneyBill} className="me-1" />
                  Deduction Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={active === "4"}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  <FontAwesomeIcon icon={faFileLines} className="me-1" />
                  Files
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={active === "5"}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  <FontAwesomeIcon icon={faHistory} className="me-1" />
                  Status History
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={active}>
              <TabPane tabId="1">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <div className="info-container">
                          <h5 className="mb-2">Other Information</h5>
                          <Row>
                            <Col tag="dt" sm="7" className="fw-bolder mb-1">
                              Claim Number:
                            </Col>
                            <Col tag="dd" sm="5" className="mb-1">
                              {data.claimNumber}
                            </Col>

                            <Col tag="dt" sm="7" className="fw-bolder mb-1">
                              Claim Created Date:
                            </Col>
                            <Col tag="dd" sm="5" className="mb-1">
                              {data.claimCreatedDate &&
                                formatDateTime(new Date(data.claimCreatedDate))}
                            </Col>

                            <Col tag="dt" sm="7" className="fw-bolder mb-1">
                              Claim Suspended/Closed:
                            </Col>
                            <Col tag="dd" sm="5" className="mb-1">
                              {(data.claimSuspendedOrClosedDate &&
                                formatDateTime(
                                  new Date(data.claimSuspendedOrClosedDate)
                                )) ||
                                "-"}
                            </Col>

                            <Col tag="dt" sm="7" className="fw-bolder mb-1">
                              Total Days:
                            </Col>
                            <Col tag="dd" sm="5" className="mb-1">
                              {data.totalDays || "-"}
                            </Col>

                            <Col tag="dt" sm="7" className="fw-bolder mb-1">
                              Flight Number:
                            </Col>
                            <Col tag="dd" sm="5" className="mb-1">
                              <span className="text-primary">
                                <FontAwesomeIcon icon={faPlane} />
                              </span>{" "}
                              {data.flightNumber}
                            </Col>

                            <Col tag="dt" sm="7" className="fw-bolder mb-1">
                              Flight Date:
                            </Col>
                            <Col tag="dd" sm="5" className="mb-1">
                              {formatDateOnly(new Date(data.flightDate))}
                            </Col>

                            <Col tag="dt" sm="7" className="fw-bolder mb-1">
                              Flight Type:
                            </Col>
                            <Col tag="dd" sm="5" className="mb-1">
                              {getFlightTypeIcon(data.flightTypeEnum) && (
                                <span className="text-primary">
                                  <FontAwesomeIcon
                                    icon={getFlightTypeIcon(
                                      data.flightTypeEnum
                                    )}
                                  />
                                </span>
                              )}{" "}
                              {data.flightTypeEnum}
                            </Col>
                          </Row>
                          <Row>
                            <hr />
                            <Col tag="dt" sm="12" className="fw-bolder mb-1">
                              Tracing Result:
                            </Col>
                            <Col tag="dt" sm="12" className="mb-1">
                              {data?.tracingResult
                                ? data?.tracingResult
                                : "No Tracing Result available."}
                            </Col>
                          </Row>
                          {data.caseStatus === "rejected" && (
                            <Row>
                              <hr />
                              <Col tag="dt" sm="12" className="fw-bolder mb-1">
                                Rejection Reason:
                              </Col>
                              <Col
                                tag="dt"
                                sm="12"
                                className="mb-1 text-danger"
                              >
                                {data?.rejectionReason
                                  ? data?.rejectionReason
                                  : "No Rejection Reason available."}
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardBody>
                    <Row>
                      <h5 className="mb-2">Passenger Information</h5>
                      <Col sm="6">
                        <div className="info-container">
                          <Row>
                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Passenger Name:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger.passengerName}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              PNR:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger.pnr}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Tag Number:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger?.tagNo || "-"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Phone:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger.phoneCountryCode}{" "}
                              {data.passenger.phoneNumber}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Checked Bags:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger.checkedBagsCount} bags (
                              {data.passenger.checkedBagsWeight} kg)
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Received Bags:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger.receivedBagsCount} bags (
                              {data.passenger.receivedBagsWeight} kg)
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Missing Bags:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger.missingBagsCount} bags (
                              {data.passenger.missingWeight} kg)
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Insurance:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger.hasInsurance ? "Yes" : "No"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Excess Bag Paid:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger?.excessBagPaid || "-"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Has Insurance:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.passenger?.hasInsurance ? "Yes" : "No"}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="info-container">
                          <h5 className="mb-2 section-label">Bank Details</h5>
                          <Row>
                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Account Type:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails.bankAccountType ==
                              "local_Account" ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faHome}
                                    className="text-primary"
                                  />{" "}
                                  Local
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faGlobe}
                                    className="text-primary"
                                  />{" "}
                                  International
                                </>
                              )}
                            </Col>
                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Bank Name:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails.bankName || "-"}
                            </Col>
                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              IBAN:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails.iban || "-"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Currency:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails.currency || "-"}
                            </Col>
                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Account Number:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails?.accountNumber || "-"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Swift Code:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails?.swiftCode || "-"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              First Name:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails?.firstName || "-"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Middle Name:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails?.middleName || "-"}
                            </Col>

                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Last Name:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails?.lastName || "-"}
                            </Col>
                            <Col tag="dt" sm="6" className="fw-bolder mb-1">
                              Is Other Person:
                            </Col>
                            <Col tag="dd" sm="6" className="mb-1">
                              {data.bankDetails?.isOtherPerson ? "Yes" : "No"}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="3">
                <Card>
                  <CardBody>
                    <div className="info-container">
                      <h5 className="mb-2">Deduction Details</h5>
                      <Row>
                        <Col tag="dt" sm="3" className="fw-bolder mb-1">
                          Deduction Date:
                        </Col>
                        <Col tag="dd" sm="9" className="mb-1">
                          {data.compensationDeductionDetail?.deductionDate &&
                            formatDateTime(
                              new Date(
                                data.compensationDeductionDetail.deductionDate
                              )
                            )}
                        </Col>

                        <Col tag="dt" sm="3" className="fw-bolder mb-1">
                          Handler Name:
                        </Col>
                        <Col tag="dd" sm="9" className="mb-1">
                          {data.compensationDeductionDetail?.handlerName || "-"}
                        </Col>

                        <Col tag="dt" sm="3" className="fw-bolder mb-1">
                          Reason:
                        </Col>
                        <Col tag="dd" sm="9" className="mb-1">
                          {data.compensationDeductionDetail?.deductionReason ||
                            "-"}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="4">
                <Card>
                  <CardBody>
                    <FileDetailView
                      files={data.files}
                      category="Compensation"
                    />
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="5">
                <Card>
                  <CardBody>
                    {" "}
                    <Timeline
                      data={data.statusHistories.map((history) => ({
                        title: (
                          <span
                            className={`text-${getStatusSeverity(
                              history.status
                            )}`}
                          >
                            {history.status}
                          </span>
                        ),
                        content: formatDateTime(new Date(history.createdOnUtc)),
                        meta: `By ${history.createdBy}`,
                        color: getStatusSeverity(history.status),
                      }))}
                    />
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <p>{error ? error : "No data available."}</p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CompensationDetails;
